import moment from 'moment';

export const formateDate = (date) => {

    const options = {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
    }

    return new Date(date).toLocaleDateString('es-ES', options) + ' a las ' + moment(date).format('hh:mm a');
}