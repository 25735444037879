import { Typography } from '@mui/material'
import React from 'react'


export const EventFirm = (props) => {

    
    const { buyerRef, firmLocation = '', propertyRef } = props?.infoCliente

    

    return (
        <Typography variant='body1'>
            <b>Cliente:</b>{buyerRef?.name},{'  '}<b>Ubicacion</b>: {firmLocation}
            {' '} <b>Asesor: </b> {propertyRef?.saleAdviserRef?.name}
        </Typography>
    )
}
