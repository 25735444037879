import { Grid, TableCell } from '@mui/material';
import AppActionButton from '../../../components/AppActionButton';
import AppBuyRequestTile from '../../../components/AppBuyRequestTile';
import FileIntegration from './FileIntegration';
import { useModal } from '../../../hooks/useModal';
import { ModalReconversion } from '../../../components/ModalReconversion';
import { BUY_REQUESTS_STATUS } from '../../../constants/buyRequestsConstants';


const FileListItem = props => {

  const modalFile = useModal();
  const modalReconversion = useModal();

  return (
    <AppBuyRequestTile {...props}>
      <TableCell>
        <Grid container>
          <Grid item xs={12}>
            <AppActionButton fullWidth onClick={modalFile.onOpenModal}>Integrar expediente</AppActionButton>
          </Grid>
          <Grid item xs={12}>
            <AppActionButton fullWidth onClick={modalReconversion.onOpenModal} color='secondary'>Reconversion</AppActionButton>
          </Grid>
        </Grid>
      </TableCell>
      <FileIntegration open={modalFile.open} onClose={modalFile.onCloseModal} {...props} />
      <ModalReconversion open={modalReconversion.open} onClose={modalReconversion.onCloseModal} _id={props._id} query={BUY_REQUESTS_STATUS.FILE} />
    </AppBuyRequestTile>
  );
}

export default FileListItem;
