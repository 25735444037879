import { TableCell, TableRow, Typography } from '@mui/material';
import { formatCurrency } from '../utils/currencyUtils';

const AppBuyRequestTile = ({ buyerRef, propertyRef, adviserRef, ...props }) => {
  const address = `${propertyRef?.address?.street ?? ''} ${propertyRef?.address?.numExt ?? ''} ${propertyRef?.settlement ?? ''}`;


  return (
    <TableRow>
      <TableCell>{propertyRef?._id ?? 'N/A'}</TableCell>
      <TableCell>{formatCurrency(props?.offer) ?? 'N/A'}</TableCell>
      <TableCell>
        <Typography variant="body2"><b>Nombre:</b> {buyerRef?.name}</Typography>
        <Typography variant="body2"><b>Correo:</b> {buyerRef?.email}</Typography>
        <Typography variant="body2"><b>Teléfono:</b> {buyerRef?.phoneNumber}</Typography>
        <Typography variant="body2"><b>Casado:</b> {buyerRef?.married ? 'Si' : 'No'}</Typography>
        <Typography variant="body2"><b>Tipo de cŕedito:</b> Infonavit</Typography>
      </TableCell>
      <TableCell>
        {
          propertyRef
            ? (
              <>
                <Typography variant="body2"><b>Estado:</b> {propertyRef?.state ?? 'N/A'}</Typography>
                <Typography variant="body2"><b>Ubicación:</b> {address ?? 'N/A'}</Typography>


                <Typography variant="body2"><b>Asesor asignado:</b></Typography>
                {propertyRef.saleAdvisersRef.map(adviser => (
                  <Typography variant="body2" key={adviser?._id}><b>{adviser?.name}</b></Typography>
                ))}
              </>
            )
            : <Typography variant="body2"><b>N/A</b></Typography>
        }
      </TableCell>
      <TableCell>{buyerRef?.source ?? 'N/A'}</TableCell>
      {props.children}
    </TableRow>
  );
}

export default AppBuyRequestTile;
