import { useEffect } from 'react';
import { onMessage } from 'firebase/messaging'
import { ThemeProvider } from '@mui/material/styles'
import Router from './Router';
import LoadingDialog from './components/LoadingDialog';
import FeedbackAlert from './components/FeedbackAlert';
import ErrorsDialog from './components/ErrorsDialog';
import { generateTokenMessaging, messaging } from './firebase/firebaseNotifications';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from './components/Notification';
import { toast } from 'react-toastify';
import { getMeCurrentUser, setFcmTokenUser } from './actions/authActions';
import { getMeNotifications, getNewsNotifications } from './actions/notificationsActions';

import theme from './config/theme';
import './App.css';



function App() {


  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);

  

  useEffect(() => {
    if (token) {
      dispatch(getMeCurrentUser());
      dispatch(getMeNotifications({ query: {} }));
    }
  }, [token]);



  useEffect(() => {
    if (token) {
      generateTokenMessaging()
        .then(fcmToken => dispatch(setFcmTokenUser({ fcmToken })))
        .catch(error => console.log(error))
    }
  }, [token]);


  useEffect(() => {
    if (messaging) {
      onMessage(messaging, showNotification)
    }
  }, []);


  const showNotification = (response) => {
    dispatch(getNewsNotifications({
      callback: () => {
        toast(response.notification.body, { type: 'success' });
      }
    }))
  }



  return (
    <ThemeProvider theme={theme}>
      <Router />
      <LoadingDialog />
      <FeedbackAlert />
      <ErrorsDialog />
      <Notification />
    </ThemeProvider>
  );
}

export default App;
