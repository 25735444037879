import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateBuyer } from '../api/buyersApi';
import { showAlert } from '../slices/alertsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const updateCurrentBuyer = createAsyncThunk('buyers/updateCurrentBuyer', ({ id, buyer }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  updateBuyer(token, id, buyer)
    .then(response => response.status === 200 ? response.json() : Promise.reject(response))
    .then(() => dispatch(showAlert({ message: 'Comprador actualizado', type: 'success' })))
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(endLoading()));
});
