const { REACT_APP_API_BASE_URL } = process.env;


export const createCredit = (token, credit) => new Promise((resolve, reject) => {

    const request = new Request(REACT_APP_API_BASE_URL + '/credits', {
        method: 'POST',
        body: JSON.stringify({ credit }),
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    })

    fetch(request)
        .then(response => resolve(response))
        .catch(error => reject(error))
});

