import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllNotification, updateNotification } from '../api/notificationsApi';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';
import { setCurrentNotification, setNewNotifications, setNotifications } from '../slices/notificationsSlice';


export const getMeNotifications = createAsyncThunk('notifications/getNotification', ({ query }, { getState, dispatch }) => {

    const { token } = getState().auth;


    dispatch(startLoading());

    getAllNotification(token, query)
        .then(response => response.status === 200 ? response.json() : Promise.reject(response))
        .then(data => {
            dispatch(setNewNotifications({ newNotification: data.notifications.newNotification }));
            dispatch(setNotifications({ notifications: data.notifications.notifications }));
        })
        .catch(err => dispatch(handleError(err)))
        .finally(() => dispatch(endLoading()));
})

export const getNewsNotifications = createAsyncThunk('notifications/getNewsNotifications', ({ query = {}, callback }, { getState, dispatch }) => {

    const { token } = getState().auth;

    getAllNotification(token, query)
        .then(response => response.status === 200 ? response.json() : Promise.reject(response))
        .then(data => {
            dispatch(setNewNotifications({ newNotification: data.notifications.newNotification }));
            dispatch(setNotifications({ notifications: data.notifications.notifications }));
        })
        .then(() => callback())
        .catch(err => dispatch(handleError(err)))
})


export const updateCurrentNotification = createAsyncThunk('notifications/updateCurrentNotification', ({ notification, query }, { getState, dispatch }) => {

    const { token } = getState().auth;

    dispatch(setCurrentNotification({ currentNotification: notification }));

    updateNotification(token, notification?._id, { seen: true })
        .then(response => response.status === 200 ? response.json() : Promise.reject(response))
        .then(() => getAllNotification(token, query))
        .then(response => response.status === 200 ? response.json() : Promise.reject(response))
        .then(data => {
            dispatch(setNewNotifications({ newNotification: data.notifications.newNotification }));
            dispatch(setNotifications({ notifications: data.notifications.notifications }));
        })
        .catch(err => dispatch(handleError(err)))
})