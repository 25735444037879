import styled from '@emotion/styled';
import { Button } from '@mui/material';

const AppActionButton = styled(Button)(({ theme }) => ({
  margin: '0.3rem 0.2rem',
  padding: '0.25rem',
  borderRadius: '2rem'
}));

export default AppActionButton;
