import { useDispatch } from "react-redux";
import { Grid, TableCell } from "@mui/material";
import { BUY_REQUESTS_STATUS } from "../../../constants/buyRequestsConstants";
import AppActionButton from "../../../components/AppActionButton";
import AppBuyRequestTile from "../../../components/AppBuyRequestTile";
import ProspectsSearchHouse from "./ProspectsSearchHouse";

import { updateCurrentBuyRequest } from "../../../actions/buyRequestsActions";
import ProspectsDate from "./ProspectsDate";
import { ModalReconversion } from "../../../components/ModalReconversion";
import { useModal } from "../../../hooks/useModal";

const ProspectsListItem = (props) => {
  const modalReconversion = useModal();
  const modalSearchHouse = useModal();
  const modalProspectsDate = useModal();
  const dispatch = useDispatch();

  const handleToFile = () => {
    dispatch(
      updateCurrentBuyRequest({
        id: props._id,
        buyRequest: { status: BUY_REQUESTS_STATUS.FILE },
        query: { status: BUY_REQUESTS_STATUS.PROSPECTS },
      })
    );
  };

  return (
    <>
      <AppBuyRequestTile {...props}>
        <TableCell>
          <AppActionButton
            fullWidth
            onClick={modalProspectsDate.onOpenModal}
            sx={{ width: 180 }}
          >
            Agendar Visita
          </AppActionButton>
        </TableCell>
        <TableCell>
          <Grid container>
            <Grid item xs={12}>
              <AppActionButton fullWidth onClick={modalSearchHouse.onOpenModal}>
                Buscar casa
              </AppActionButton>
            </Grid>
            <Grid item xs={12}>
              <AppActionButton
                fullWidth
                onClick={handleToFile}
                disabled={!props.visitDate}
              >
                Enviar a Expediente
              </AppActionButton>
            </Grid>
            <Grid item xs={12}>
              <AppActionButton
                fullWidth
                color="secondary"
                onClick={modalReconversion.onOpenModal}
              >
                Enviar a Reconversión
              </AppActionButton>
            </Grid>
          </Grid>
          <ProspectsSearchHouse
            open={modalSearchHouse.open}
            onClose={modalSearchHouse.onCloseModal}
            {...props}
          />
        </TableCell>
      </AppBuyRequestTile>
      <ModalReconversion
        open={modalReconversion.open}
        onClose={modalReconversion.onCloseModal}
        _id={props._id}
        query={BUY_REQUESTS_STATUS.PROSPECTS}
      />
      <ProspectsDate
        open={modalProspectsDate.open}
        onClose={modalProspectsDate.onCloseModal}
        {...props}
      />
    </>
  );
};

export default ProspectsListItem;
