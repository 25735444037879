export const optionsReconversion = [
    'Adeudo Mayor',
    'Viviendas sin formalidad',
    'La casa esta invadida',
    'No le interesa vender',
    'No se registro',
    'No se compra dentro de ese fraccionamiento',
    'Lead sin datos',
    'Lead repetido',
    'Datos incorrectos',
];