import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';
import ProspectsListItem from './ProspectsListItem';

const ProspectsListTable = () => {
  const buyRequests = useSelector(state => state.buyRequests.list);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id de la casa</TableCell>
            <TableCell>Precio</TableCell>
            <TableCell>Información del cliente</TableCell>
            <TableCell>Información del inmueble</TableCell>
            <TableCell>Fuente</TableCell>
            <TableCell>Fecha establecida</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {buyRequests.map(buyRequest => <ProspectsListItem key={buyRequest._id} {...buyRequest} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ProspectsListTable;
