const { REACT_APP_API_BASE_URL } = process.env;


export const getAllStates = (token, query) => new Promise((resolve, reject) => {

    const queryParams = new URLSearchParams({ ...query }).toString();

    const request = new Request(REACT_APP_API_BASE_URL + '/states?' + queryParams, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    });

    fetch(request)
        .then(response => resolve(response))
        .catch(error => reject(error))
});


export const getAllCities = (token, query) => new Promise((resolve, reject) => {

    const queryParams = new URLSearchParams({ ...query }).toString();

    const request = new Request(REACT_APP_API_BASE_URL + '/states/cities?' + queryParams, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    });

    
    fetch(request)
        .then(response => resolve(response))
        .catch(error => reject(error))
});



export const getAllSettlements = (token, query) => new Promise((resolve, reject) => {

    const queryParams = new URLSearchParams({ ...query }).toString();

    const request = new Request(REACT_APP_API_BASE_URL + '/states/settlements?' + queryParams, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    });

    fetch(request)
        .then(response => resolve(response))
        .catch(error => reject(error))
});