const { REACT_APP_API_BASE_URL } = process.env;

export const uploadTitling = (token, propertyId, doc) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("doc", doc);

    const request = new Request(
      REACT_APP_API_BASE_URL + "/properties/" + propertyId + "/titling",
      {
        method: "PUT",
        body: formData,
        headers: { Authorization: "Bearer " + token },
      }
    );

    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const getPropertiesForSale = (token, query) =>
  new Promise((resolve, reject) => {
    const queryParams = new URLSearchParams({ ...query }).toString();

    const request = new Request(
      REACT_APP_API_BASE_URL +
        "/properties/for-sale?results=100&page=1&" +
        queryParams,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    console.log(request);
    fetch(request)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });

export const getPropertieById = (token, id) =>
  new Promise((resolve, reject) => {
    const request = new Request(REACT_APP_API_BASE_URL + "/properties/" + id, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });

    fetch(request)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
