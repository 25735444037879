const { REACT_APP_API_BASE_URL } = process.env;


export const getTotalLeadByStatus = (token) => new Promise((resolve, reject) => {

    const request = new Request(REACT_APP_API_BASE_URL + '/metrics/sale-reconversion', {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    });

    fetch(request)
        .then(response => resolve(response))
        .catch(error => reject(error))
});