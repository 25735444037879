const { REACT_APP_API_BASE_URL } = process.env;

export const getReconversions = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    // results: 10,
    ...query
  }).toString();
  const request = new Request(REACT_APP_API_BASE_URL + '/buy-reconversions?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
    .then(response => resolve(response))
    .catch(err => reject(err))
});

export const getReconversion = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/buy-reconversions/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
    .then(response => resolve(response))
    .catch(err => reject(err))
});

export const createReconversion = (token, reconversion) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/buy-reconversions', {
    method: 'POST',
    body: JSON.stringify({ reconversion }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
    .then(response => resolve(response))
    .catch(err => reject(err))
});

export const updateReconversion = (token, id, reconversion) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/buy-reconversions/' + id, {
    method: 'PUT',
    body: JSON.stringify({ reconversion }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
    .then(response => resolve(response))
    .catch(err => reject(err))
});



