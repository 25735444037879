const { REACT_APP_API_BASE_URL } = process.env;

export const getFiles = (token, query) =>
  new Promise((resolve, reject) => {
    const queryParams = new URLSearchParams({
      ...query,
    }).toString();

    const request = new Request(
      REACT_APP_API_BASE_URL + "/documentations/buyers?" + queryParams,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const getFilesOptions = (token, query) =>
  new Promise((resolve, reject) => {
    // const queryParams = new URLSearchParams({ ...query }).toString();

    const request = new Request(
      REACT_APP_API_BASE_URL + "/documentations/list-buyers?" + query,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const createFile = (token, file) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_API_BASE_URL + "/documentations/buyer",
      {
        method: "POST",
        body: JSON.stringify({ documentation: file }),
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const uploadFile = (token, id, doc) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("doc", doc);

    const request = new Request(
      REACT_APP_API_BASE_URL + "/documentations/" + id + "/pdf",
      {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const deleteFile = (token, id) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_API_BASE_URL + "/documentations/" + id,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const updateFile = (token, id, fileData) =>
  new Promise((resolve, reject) => {
    const documentations = fileData;
    const request = new Request(
      REACT_APP_API_BASE_URL + "/documentations/" + id,
      {
        method: "PUT",
        body: JSON.stringify({ documentations }),
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => {
        console.log(response);
        return resolve(response);
      })
      .catch((err) => reject(err));
  });
