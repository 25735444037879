import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createBuyRequest,
  getBuyRequest,
  getBuyRequests,
  getBuyRequestsFormFirm,
  updateBuyRequest,
  uploadedBuyRequests,
} from "../api/buyRequestsApi";
import { getAllHistories } from "../api/historiesApi";
import { uploadTitling } from "../api/propertiesApi";
import { showAlert } from "../slices/alertsSlice";
import {
  setCurrentBuyRequest,
  setBuyRequestsList,
  setBuyRequestsListForFirm,
  setBuyRequestsListHistory,
} from "../slices/buyRequestsSlice";
import { handleError } from "../slices/errorsSlice";
import { startLoading, endLoading } from "../slices/loadingSlice";

export const getBuyRequestsList = createAsyncThunk(
  "buyRequests/getBuyRequestsList",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getBuyRequests(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setBuyRequestsList({ buyRequests: data.buyRequests }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getCurrentBuyRequest = createAsyncThunk(
  "buyRequests/getCurrentBuyRequest",
  ({ id }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getBuyRequest(token, id)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setCurrentBuyRequest({ buyRequest: data.buyRequest }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const createNewBuyRequest = createAsyncThunk(
  "buyRequests/createNewBuyRequest",
  ({ buyRequest, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    createBuyRequest(token, buyRequest)
      .then((response) =>
        response.status === 201 ? response.json() : Promise.reject(response)
      )
      .then((data) => callback(data.buyRequest))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const updateCurrentBuyRequest = createAsyncThunk(
  "buyRequests/updateCurrentBuyRequest",
  ({ id, buyRequest, query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    updateBuyRequest(token, id, buyRequest)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setCurrentBuyRequest({ buyRequest: data.buyRequest }))
      )
      .then(() =>
        dispatch(
          showAlert({ message: "Propiedad actualizada", type: "success" })
        )
      )
      .then(() => dispatch(getBuyRequestsList({ query })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const updateUploadedBuyRequests = createAsyncThunk(
  "buy-requests",
  ({ id, buyRequest }, { getState, dispatch }) => {
    const { token } = getState().auth;
    console.log("desde updateUploadedBuyRequests");
    dispatch(startLoading());

    uploadedBuyRequests(token, id, buyRequest)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getBuyRequestsListForFirm = createAsyncThunk(
  "buyRequests/getBuyRequestsListForFirm",
  ({ term }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getBuyRequestsFormFirm(token, term)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setBuyRequestsListForFirm({ buyRequests: data.buyRequests }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const uploadPropertyTitling = createAsyncThunk(
  "buyRequest/uploadTitling",
  ({ id, doc, query, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    uploadTitling(token, id, doc)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then(() =>
        dispatch(showAlert({ message: "Titulacion subida", type: "success" }))
      )
      .then(() => dispatch(getBuyRequestsList({ query })))
      .then(() => callback())
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getHistoryBuyRequest = createAsyncThunk(
  "buyRequest/getHistoryBuyRequest",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getAllHistories(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setBuyRequestsListHistory({ history: data.history }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const assingPropertyOfBuyRequest = createAsyncThunk(
  "buyRequest/assingPropertyOfBuyRequest",
  ({ id, buyRequest, postDispatch }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    updateBuyRequest(token, id, buyRequest)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then(() => postDispatch())
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);
