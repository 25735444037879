

const docs = [
    'INE Ratificada con Validación',
    'Acta de nacimiento con validación',
    'CURP', 'RFC', 'Comprobante de domicilio no mayor a 3 meses',
    'Precalificación', 'Solicitud Inscripción de crédito', 'Solicitud Avaluo',
    'Bansefi', 'Taller', 'SICs', 'Hoja de datos generales',
    'Contrato', 'Formatos CTC', 'Ficha de apartado', 'Recibo de Nomina'
]


const isMarriedDoc = [
    'Acta de matrimonio',
];


const iscommunityPropertyDoc = [
    'INE Ratificada con Validación (conyuge)',
    'Acta de nacimiento con validación (conyuge)',
    'CURP (conyuge)', 'RFC (conyuge)'
]


export const isValidListDocs = (listDocs = [], isMarried = false, isCommunityProperty = false) => {

    if (listDocs.length === 0) return false;

    const typesDocs = listDocs.map(doc => doc.type);

    let marriedDocs = [];

    if (isMarried) {
        marriedDocs = isCommunityProperty ? [...isMarriedDoc, ...iscommunityPropertyDoc] : [...isMarriedDoc]
    }

    const requiredDocs = isMarried ? [...docs, ...marriedDocs] : [...docs];

    const buyerDocs = isMarried ? typesDocs : typesDocs.filter(doc => !marriedDocs.includes(doc))

    return JSON.stringify([...new Set(buyerDocs.sort())]) === JSON.stringify([...new Set(requiredDocs.flat().sort())]);
}


