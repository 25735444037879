import { createAsyncThunk } from '@reduxjs/toolkit';
import { createReconversion, getReconversion, getReconversions, updateReconversion } from '../api/reconversionsApi';
import { showAlert } from '../slices/alertsSlice';
import { setCurrentReconversion, setReconversionsList, setReconversionsMetrics } from '../slices/reconversionsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';
import { updateBuyRequest } from '../api/buyRequestsApi';
import { createCall } from '../api/callApi';
import { getBuyRequestsList } from './buyRequestsActions';
import { getTotalLeadByStatus } from '../api/metricsApi';

export const getReconversionsList = createAsyncThunk('reconversions/getReconversionsList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getReconversions(token, query)
    .then(response => response.status === 200 ? response.json() : Promise.reject(response))
    .then(data => dispatch(setReconversionsList({ reconversions: data.reconversions })))
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(endLoading()));
});

export const getCurrentReconversion = createAsyncThunk('reconversions/getCurrentReconversion', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getReconversion(token, id)
    .then(response => response.status === 200 ? response.json() : Promise.reject(response))
    .then(data => dispatch(setCurrentReconversion({ reconversion: data.reconversion })))
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(endLoading()));
});


export const createNewReconversion = createAsyncThunk('buyRequests/createNewReconversion', ({ id, query, buyRequest }, { getState, dispatch }) => {

  const { token } = getState().auth;

  dispatch(startLoading());

  updateBuyRequest(token, id, { reconversion: true })
    .then(response => response.status === 200 ? response.json() : Promise.reject(response))
    .then(() => createReconversion(token, { buyRequestRef: id, ...buyRequest }))
    .then(response => response.status === 201 ? response.json() : Promise.reject(response))
    .then(() => createCall(token, { buyRequestRef: id, ...buyRequest }))
    .then(response => response.status === 201 ? response.json() : Promise.reject(response))
    .then(() => dispatch(showAlert({ message: 'Propiedad actualizada', type: 'success' })))
    .then(() => dispatch(getBuyRequestsList({ query })))
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(endLoading()));
})


export const updateCurrentReconversion = createAsyncThunk('reconversions/updateCurrentReconversion', ({ id, reconversion, query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  updateReconversion(token, id, reconversion)
    .then(response => response.status === 200 ? response.json() : Promise.reject(response))
    .then(data => dispatch(setCurrentReconversion({ reconversion: data.reconversion })))
    .then(() => dispatch(showAlert({ message: 'Lead actualizado', type: 'success' })))
    .then(() => dispatch(getReconversionsMetrics()))
    .then(() => dispatch(getReconversionsList({ query })))
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(endLoading()));
});



export const deleteLead = createAsyncThunk('reconversion/deleteLead', ({ buyRequest_id, buyRequest, query }, { getState, dispatch }) => {

  const { token } = getState().auth;

  dispatch(startLoading());

  updateBuyRequest(token, buyRequest_id, buyRequest)
    .then(response => response.status === 200 ? response.json() : Promise.reject(response))
    .then(() => dispatch(showAlert({ message: 'Lead Eliminado', type: 'success' })))
    .then(() => dispatch(getReconversionsMetrics()))
    .then(() => dispatch(getReconversionsList({ query })))
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(endLoading()));
});


export const reassignAdviser = createAsyncThunk('reconversion/reassignAdviser', ({ buyRequest_id, buyRequest, query }, { getState, dispatch }) => {


  const { token } = getState().auth;

  dispatch(startLoading());


  updateBuyRequest(token, buyRequest_id, buyRequest)
    .then(response => response.status === 200 ? response.json() : Promise.reject(response))
    .then(() => dispatch(showAlert({ message: 'Lead Actualizado', type: 'success' })))
    .then(() => dispatch(getReconversionsMetrics()))
    .then(() => dispatch(getReconversionsList({ query })))
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(endLoading()));
});


export const newOfferBuyRequest = createAsyncThunk('reconversion/reassignAdviser', ({ buyRequest_id, reconversion_id, reconversion, query }, { getState, dispatch }) => {


  const { token } = getState().auth;

  dispatch(startLoading());


  updateReconversion(token, reconversion_id, reconversion)
    .then(response => response.status === 200 ? response.json() : Promise.reject(response))
    .then(() => updateBuyRequest(token, buyRequest_id, { reconversion: false }))
    .then(response => response.status === 200 ? response.json() : Promise.reject(response))
    .then(() => dispatch(showAlert({ message: 'Lead Actualizado', type: 'success' })))
    .then(() => dispatch(getReconversionsMetrics()))
    .then(() => dispatch(getReconversionsList({ query })))
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(endLoading()));
});



export const getReconversionsMetrics = createAsyncThunk('reconversions/getReconversionsMetrics', (_, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getTotalLeadByStatus(token)
    .then(response => response.status === 200 ? response.json() : Promise.reject(response))
    .then(data => dispatch(setReconversionsMetrics({ metrics: data.metrics })))
    .catch(err => dispatch(handleError(err)))
    .finally(() => dispatch(endLoading()));
});