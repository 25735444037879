import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment'
import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Box, Button, DialogActions, TextField, Grid, Divider, Typography, IconButton, Chip } from '@mui/material';
import { updateCurrentBuyRequest } from '../../../actions/buyRequestsActions';
import { BUY_REQUESTS_STATUS } from '../../../constants/buyRequestsConstants';

const styles = {
    container: {
        display: 'block',
    },
    content: {
        paddingY: 0,
    },
    actions: {
        paddingTop: 0,
        paddingRight: '1rem'
    },
    title: {
        my: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    fieldWrapper: {
        px: '1rem'
    }
}

const ProspectsDate = props => {

    const dispatch = useDispatch();
    const [visitDate, setVisitDate] = useState('');

    const handleClose = e => {
        e.preventDefault();
        props.onClose();
    }

    useEffect(() => {
        const date = props?.visitDate ? moment(props?.visitDate).format('YYYY-MM-DDTkk:mm') : '';
        setVisitDate(date)
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        dispatch(updateCurrentBuyRequest({ id: props._id, buyRequest: { visitDate, visitCancellation: false }, query: { status: BUY_REQUESTS_STATUS.PROSPECTS } }));
    }


    return (
        <Dialog fullWidth open={props.open} onClose={handleClose}>
            <DialogTitle>
                <Box sx={styles.title}>
                    <Typography variant="h5">Visita</Typography>
                    {props.visitCancellation && (<Chip label="Cita cancelada, por el comprador" color="error" variant="outlined" />)}
                    <IconButton onClick={handleClose}><Close /></IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent sx={styles.content}>
                <Box sx={styles.container}>
                    <Grid container>
                        <Grid item sx={styles.fieldWrapper} xs={12}>
                            <TextField label="Fecha" type="datetime-local" name="firmDate" value={visitDate} onChange={(e) => setVisitDate(e.target.value)} color='secondary' InputLabelProps={{ shrink: true }}/>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={styles.actions}>
                <Button onClick={handleSubmit}>Guardar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProspectsDate;
