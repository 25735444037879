import React from "react";

import { DeedEvaluate } from "./DeedEvaluate";
// import { DeedShowFile } from "./DeedShowFile";
import AppBuyRequestTile from "../../../components/AppBuyRequestTile";
import { useModal } from "../../../hooks/useModal";
import AppActionButton from "../../../components/AppActionButton";
import { TableCell } from "@mui/material";
import { ModalReconversion } from "../../../components/ModalReconversion";
import { BUY_REQUESTS_STATUS } from "../../../constants/buyRequestsConstants";
import { DeedShowFile } from "./DeedShowFile";

export const DeedListItem = (props) => {
  const deedEvaluo = useModal();
  const deedReconversion = useModal();
  const deedShowFile = useModal();

  return (
    <AppBuyRequestTile {...props}>
      <TableCell>
        <AppActionButton fullWidth onClick={deedEvaluo.onOpenModal}>
          AVALÚO
        </AppActionButton>
      </TableCell>
      <TableCell style={{ width: "17%" }}>
        <AppActionButton fullWidth onClick={deedShowFile.onOpenModal}>
          validar EXPEDIENTE
        </AppActionButton>
        <AppActionButton
          fullWidth
          color="secondary"
          onClick={deedReconversion.onOpenModal}
        >
          enviar a RECONVERSIÓN
        </AppActionButton>
      </TableCell>
      <DeedShowFile
        open={deedShowFile.open}
        onClose={deedShowFile.onCloseModal}
        {...props}
      />
      {deedEvaluo.open && (
        <DeedEvaluate
          open={deedEvaluo.open}
          onClose={deedEvaluo.onCloseModal}
          {...props}
        />
      )}
      <ModalReconversion
        open={deedReconversion.open}
        onClose={deedReconversion.onCloseModal}
        _id={props._id}
        query={BUY_REQUESTS_STATUS.DEED}
      />
    </AppBuyRequestTile>
  );
};
