import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Box, Button, DialogActions, TextField, Grid, Divider, Typography, IconButton, MenuItem } from '@mui/material';
import { Close } from '@mui/icons-material';
import { getAdviserList } from '../../../actions/advisersAction';
import { reassignAdviser } from '../../../actions/reconversionsActions';

const styles = {
    container: {
        display: 'block',
    },
    content: {
        paddingY: 0,
    },
    actions: {
        paddingTop: 0,
        paddingRight: '1rem'
    },
    title: {
        my: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    fieldWrapper: {
        px: '1rem'
    }
}

export const ReconversionReassign = (props) => {

    const dispatch = useDispatch();
    const advisers = useSelector(state => state.advisers.list);
    const [adviser, setAdviser] = useState('');

    const handleClose = () => props.onClose();


    const onSubmit = () => {
        dispatch(reassignAdviser({
            buyRequest_id: props.buyRequest_id,
            buyRequest: { adviserRef: adviser, reconversion: false },
            query: { status: props.statusPage }
        }))
    }

    useEffect(() => {
        if (props.open) {
            dispatch(getAdviserList({ query: {} }))
        }
    }, [props.open]);

    return (
        <Dialog fullWidth open={props.open} onClose={handleClose}>
            <DialogTitle>
                <Box sx={styles.title}>
                    <Typography variant="h5">Reasignar</Typography>
                    <IconButton onClick={handleClose}><Close /></IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent sx={styles.content}>
                <Box sx={styles.container}>
                    <Grid container>
                        <Grid item sx={styles.fieldWrapper} xs={12}>
                            <TextField select label='Asesores' fullWidth onChange={(e) => setAdviser(e.target.value)} value={adviser} >
                                {advisers.map(adviser => (<MenuItem key={adviser._id} value={adviser._id} >{adviser.name}</MenuItem>))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={styles.actions}>
                <Button disabled={!adviser} onClick={onSubmit} >Reasignar</Button>
                <Button onClick={handleClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
}
