const { REACT_APP_API_BASE_URL } = process.env;

export const getBuyRequests = (token, query) =>
  new Promise((resolve, reject) => {
    const queryParams = new URLSearchParams({
      results: 10,
      ...query,
    }).toString();
    const request = new Request(
      REACT_APP_API_BASE_URL + "/buy-requests?" + queryParams,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const getBuyRequest = (token, id) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_API_BASE_URL + "/buy-requests/" + id,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const createBuyRequest = (token, buyRequest) =>
  new Promise((resolve, reject) => {
    const request = new Request(REACT_APP_API_BASE_URL + "/buy-requests", {
      method: "POST",
      body: JSON.stringify({ buyRequest }),
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const updateBuyRequest = (token, id, buyRequest) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_API_BASE_URL + "/buy-requests/" + id,
      {
        method: "PUT",
        body: JSON.stringify({ buyRequest }),
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );
    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const uploadedBuyRequests = async (token, id, doc) => {
  const formData = new FormData();

  formData.append("doc", doc);

  try {
    const request = new Request(
      REACT_APP_API_BASE_URL + "/buy-requests/" + id + "/evalutation",
      {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const response = await fetch(request);

    return response;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};

export const getBuyRequestsFormFirm = (token, term) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_API_BASE_URL + `/buy-requests/for-firm?term=${term}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
