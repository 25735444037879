import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Box, Button, DialogActions, TextField, Grid, Divider, Typography, IconButton } from '@mui/material';
import { updateCurrentBuyRequest } from '../../../actions/buyRequestsActions';
import { BUY_REQUESTS_STATUS } from '../../../constants/buyRequestsConstants';

const styles = {
    container: {
        display: 'block',
    },
    content: {
        paddingY: 0,
    },
    actions: {
        paddingTop: 0,
        paddingRight: '1rem'
    },
    title: {
        my: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    fieldWrapper: {
        px: '1rem'
    }
}

const SiningDateDelivery = props => {

    const dispatch = useDispatch();
    const [deliveryDate, setDeliveryDate] = useState('');
    const isDisabled = useMemo(() => {
        if(!props?.deliveryDate) return true;
        return moment().format('YYYY-MM-DD') < moment(props?.deliveryDate).format('YYYY-MM-DD')
    }, [props?.deliveryDate]);

    
    const handleClose = e => {
        e.preventDefault();
        props.onClose();
    }

    useEffect(() => {
        const date = props?.deliveryDate ? moment(props?.deliveryDate).format('YYYY-MM-DDTkk:mm') : '';
        setDeliveryDate(date)
    }, []);

    const handleSubmit = (buyRequest) => {
        dispatch(updateCurrentBuyRequest({
            id: props._id,
            buyRequest: { ...buyRequest },
            query: { status: BUY_REQUESTS_STATUS.SIGNING }
        }));
    }

    return (
        <Dialog fullWidth open={props.open} onClose={handleClose}>
            <DialogTitle>
                <Box sx={styles.title}>
                    <Typography variant="h5">Entrega</Typography>
                    <IconButton onClick={handleClose}><Close /></IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent sx={styles.content}>
                <Box sx={styles.container}>
                    <Grid container>
                        <Grid item sx={styles.fieldWrapper} xs={12}>
                            <TextField label="Fecha" type="datetime-local" name="deliveryDate" value={deliveryDate} onChange={(e) => setDeliveryDate(e.target.value)}  color='secondary' InputLabelProps={{ shrink: true }}/>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={styles.actions}>
                <Button disabled={isDisabled} onClick={() => handleSubmit({ status: BUY_REQUESTS_STATUS.DELIVERY })} >ENTREGADA</Button>
                <Button onClick={() => handleSubmit({ deliveryDate })}>Guardar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default SiningDateDelivery;
