import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBuyer } from "../api/buyersApi";
import { getPropertieById, getPropertiesForSale } from "../api/propertiesApi";
import {
  getAllCities,
  getAllSettlements,
  getAllStates,
} from "../api/statesApi";
import { handleError } from "../slices/errorsSlice";
import { endLoading, startLoading } from "../slices/loadingSlice";
import {
  setPropertiesCities,
  setPropertiesCurrent,
  setPropertiesList,
  setPropertiesSettlements,
  setPropertiesStates,
  setPropertiesBuyer,
} from "../slices/propertiesSlice";

export const getPropertiesBySearch = createAsyncThunk(
  "properties/getPropertiesForSale",
  ({ query, postDispatch }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getPropertiesForSale(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => {
        dispatch(setPropertiesList({ properties: data.properties.docs }));
        postDispatch(data.properties);
      })
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getProperty = createAsyncThunk(
  "properties/getProperty",
  ({ id, buyerRef, buyRequestRef }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getBuyer(token, buyerRef)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(
          setPropertiesBuyer({ buyer: { ...data.buyer, buyRequestRef } })
        )
      )
      .then(() => getPropertieById(token, id))
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setPropertiesCurrent({ property: data.property }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getAllStatesActive = createAsyncThunk(
  "properties/getAllStatesActive",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getAllStates(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => dispatch(setPropertiesStates({ states: data.states })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getAllCitiesByState = createAsyncThunk(
  "properties/getAllCitiesByState",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getAllCities(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setPropertiesCities({ cities: data.states[0].cities }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getAllSettlementsByState = createAsyncThunk(
  "properties/getAllSettlementsByState",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getAllSettlements(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(
          setPropertiesSettlements({
            settlements: [
              ...new Set(
                data.settlements
                  .map((settlement) => settlement.settlements)
                  .flat()
              ),
            ],
          })
        )
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);
