import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Box, Button, DialogActions, TextField, Grid, Divider, Typography, IconButton } from '@mui/material';
import { updateCurrentBuyRequest } from '../../../actions/buyRequestsActions';
import { BUY_REQUESTS_STATUS } from '../../../constants/buyRequestsConstants';

const styles = {
  container: {
    display: 'block',
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  title: {
    my: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldWrapper: {
    px: '1rem'
  }
}

const SigningDate = props => {
  const [data, setData] = useState({
    firmDate: '',
    firmLocation: ''
  });

  const dispatch = useDispatch();

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  useEffect(() => setData({
    ...data,
    firmLocation: props.firmLocation ?? '',
    firmDate: props.firmDate ? moment(props.firmDate).format('YYYY-MM-DDTkk:mm') : ''
  }), []);


  const handleChange = e => setData({ ...data, [e.target.name]: e.target.value });

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(updateCurrentBuyRequest({ id: props._id, buyRequest: { ...data }, query: { status: BUY_REQUESTS_STATUS.SIGNING } }));
  }

  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Firma</Typography>
          <IconButton onClick={handleClose}><Close /></IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.container}>
          <Grid container>
            <Grid item sx={styles.fieldWrapper} xs={12}>
              <TextField label="Fecha" type="datetime-local" name="firmDate" value={data.firmDate} onChange={handleChange} color='secondary' InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12}>
              <TextField color='secondary' label="Ubicación" name="firmLocation" value={data.firmLocation} onChange={handleChange} />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SigningDate;
