import { Box, Divider, ListItemButton, Typography } from '@mui/material';

const styles = {
  tile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: '3.2rem'
  },
  divider: {
    px: '1.5rem'
  }
}

const AppSidebarTile = ({ Icon, ...props }) => {
  return (
    <>
      <ListItemButton sx={styles.tile} onClick={props.onClick}>
        <Icon sx={styles.icon} color="white" />
        <Typography color="white.main">{props.text}</Typography>
      </ListItemButton>
      <Box sx={styles.divider}>
        <Divider color="white" />
      </Box>
    </>
  );
}

export default AppSidebarTile;
