import { useMemo } from 'react'
import { Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FileLinkDoc } from './FileLinkDoc'


const styles = {
    fileLink: {
        my: '0.5rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}




export const FileIntegrationItem = (props) => {

    const doc = useMemo(() => props.files.find(file => file.type === props.type), [props.files]);

    return (
        <>
            <Box sx={styles.fileLink}>
                {doc?._id ? <FileLinkDoc doc={doc} /> : <Typography variant='body1' my='0.2rem'>{props.type}</Typography>}
            </Box>
            <Divider />
        </>
    )
}
