import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Badge, Box, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material';
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined';
import NotificationsIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AppSidebar from '../../components/AppSidebar';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

const drawerWidth = 250;
const styles = {
  mobileDrawer: {
    display: { xs: 'block', md: 'none' },
    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
  },
  desktopDrawer: {
    display: { xs: 'none', md: 'block' },
    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
  },
  appbar: {
    padding: '1.8rem',
  },
  toolbar: {
    justifyContent: { xs: 'space-between', md: 'center' }
  },
  content: {
    py: '1rem',
    px: { xs: '1rem', md: '3rem' },
    ml: { md: `${drawerWidth}px` }
  }
}


const getTitle = path => {
  if (path.match('perfilados')) {
    return 'Perfilados';
  } else if (path.match('prospectos')) {
    return 'Prospectos'
  } else if (path.match('reconversion')) {
    return 'Reconversión'
  } else if (path.match('expediente')) {
    return 'Integración de Expediente'
  } else if (path.match('firma')) {
    return 'Firma'
  } else if (path.match('calendario')) {
    return 'Calendario'
  }else if (path.match('notificaciones')) {
    return 'Notificaciones'
  }

  return 'Compramos Tu Casa';
}

const Dashboard = () => {

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('Compramos Tu Casa');
  const { newNotification } = useSelector(state => state.notifications);
  const isValidPageForCalendar = useMemo(() => ['Firma', 'Prospectos'].includes(title), [title])

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => setTitle(getTitle(location.pathname)), [location]);


  return (
    <>
      <AppBar position="static" sx={styles.appbar} color="white">
        <Toolbar variant="dense" sx={styles.toolbar}>
          <IconButton edge="start" color="inherit" sx={{ mr: 1.5, display: { md: 'none' } }} onClick={openDrawer}>
            <Menu />
          </IconButton>

          <Grid container alignItems='center' >

            <Grid item xs={isValidPageForCalendar ? 11 : 12}>
              <Typography variant="h5" color="primary" fontWeight="bold" component="div" align='center' sx={{ pl: { md: isValidPageForCalendar ? '10rem' : '0' } }} >{title}</Typography>
            </Grid>
          </Grid>

          {isValidPageForCalendar && (
            <Grid item xs={1}  >
              <IconButton onClick={() => navigate(`/dashboard/calendario/${title.toLocaleLowerCase()}`)} >
                <CalendarIcon />
              </IconButton>
            </Grid>
          )}


          <Grid>
            <IconButton onClick={() => navigate(`/dashboard/notificaciones`)} >
              <Badge badgeContent={ newNotification > 100 ? '+99' : newNotification  } color="primary">
                <NotificationsIcon color="action" />
              </Badge>
            </IconButton>
          </Grid>

        </Toolbar>
      </AppBar>
      <AppSidebar variant="temporary" open={open} onClose={closeDrawer} sx={styles.mobileDrawer} />
      <AppSidebar variant="permanent" sx={styles.desktopDrawer} />
      <Box sx={styles.content}>
        <Outlet />
      </Box>
    </>
  );
}

export default Dashboard;
