const { REACT_APP_API_BASE_URL } = process.env;

export const loginAdmin = (email, password) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/admins/login', {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
    .then(response => resolve(response))
    .catch(err => reject(err))
});


export const loginUser = ({ email, password, userType }) => new Promise((resolve, reject) => {

  const request = new Request(`${REACT_APP_API_BASE_URL}/${userType}/login`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
    .then(response => resolve(response))
    .catch(error => reject(error))
});




export const logoutUser = (token, userType) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/' + userType + '/logout', {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
    .then(response => resolve(response))
    .catch(err => reject(err))
});



export const getMeUser = (token, userType) => new Promise((resolve, reject) => {

  const request = new Request(`${REACT_APP_API_BASE_URL}/${userType}/me`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });


  fetch(request)
    .then(response => resolve(response))
    .catch(error => reject(error))
});



export const setFcmToken = (token, fcmToken) => new Promise((resolve, reject) => {

  const request = new Request(REACT_APP_API_BASE_URL + '/auth/fcm-token', {
    method: 'PUT',
    body: JSON.stringify({ data: { token: fcmToken } }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });


  fetch(request)
    .then(response => resolve(response))
    .catch(error => reject(error))
});