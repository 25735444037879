import { useDispatch, } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Box, Button, DialogActions, TextField, Grid, Divider, Typography, IconButton, MenuItem } from '@mui/material';
import { Close } from '@mui/icons-material';
import { newOfferBuyRequest } from '../../../actions/reconversionsActions';
import { formatCurrency } from '../../../utils/currencyUtils';
import { useState } from 'react';

const styles = {
    container: {
        display: 'block',
    },
    content: {
        paddingY: 0,
    },
    actions: {
        paddingTop: 0,
        paddingRight: '1rem'
    },
    title: {
        my: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    fieldWrapper: {
        px: '1rem'
    }
}

export const ReconversionOffer = (props) => {

    const dispatch = useDispatch();
    const [offerPercentage, setOfferPercentage] = useState('');


    const handleClose = () => props.onClose();


    const onSubmit = () => {
        dispatch(newOfferBuyRequest({
            buyRequest_id: props.buyRequestRef?._id,
            reconversion_id: props?._id,
            reconversion: { newOffer: true, offerPercentage },
            query: { status: props.statusPage }
        }))
    }



    return (
        <Dialog fullWidth open={props.open} onClose={handleClose}>
            <DialogTitle>
                <Box sx={styles.title}>
                    <Typography variant="h5">Reasignar</Typography>
                    <IconButton onClick={handleClose}><Close /></IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent sx={styles.content}>
                <Box sx={styles.container}>
                    <Grid container>
                        <Grid item sx={styles.fieldWrapper} xs={12}>
                            <Typography variant='h6' ><b>Oferta de Venta</b>: {formatCurrency(props?.buyRequestRef?.offer)}</Typography>
                        </Grid>
                        <Grid item sx={styles.fieldWrapper} xs={12}>
                            <TextField select label='Porcentaje' value={offerPercentage} onChange={e => setOfferPercentage(e.target.value)} >
                                <MenuItem value={10} >10%</MenuItem>
                                <MenuItem value={20} >20%</MenuItem>
                                <MenuItem value={30} >30%</MenuItem>
                                <MenuItem value={40} >40%</MenuItem>
                                <MenuItem value={50} >50%</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={styles.actions}>
                <Button disabled={!offerPercentage} onClick={onSubmit} >Enviar</Button>
                <Button onClick={handleClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
}
