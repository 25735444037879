import { useEffect, useState } from 'react'
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { getProperty } from '../../../actions/propertiesAction';
import { formatCurrency } from '../../../utils/currencyUtils';
import { assingPropertyOfBuyRequest } from '../../../actions/buyRequestsActions';

import BathtubIcon from '@mui/icons-material/Bathtub';
import BedIcon from '@mui/icons-material/Bed';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

const { REACT_APP_HOST_URL } = process.env;

export const Property = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [existBuyer, setExistBuyer] = useState(true);
    const { current = [], buyer = {} } = useSelector(state => state.properties);

    useEffect(() => {
        const infoClient = JSON.parse(localStorage.getItem('infoClient')) ?? null;

        if (infoClient === null) {
            return setExistBuyer(false);
        }

        dispatch(getProperty({ id, buyerRef: infoClient?.buyerRef, buyRequestRef: infoClient?.buyRequestRef }));
    }, [])


    const onSubmit = () => {
        dispatch(assingPropertyOfBuyRequest({
            id: buyer.buyRequestRef,
            buyRequest: { propertyRef: current?._id },
            postDispatch,
        }));
    }

    const postDispatch = () => {
        window.localStorage.removeItem('infoClient');
        navigate('/dashboard/prospectos');
    }

    const img = current?.images?.length >= 1 ? current?.images[0]?.path : '';

    console.log(current)

    return existBuyer === false ? <Navigate to='/dashboard/prospectos' /> : (
        <>
            <Box xs={12} marginBottom='2rem' display='flex' justifyContent='space-between' alignItems='center' >
                <Typography sx={{ cursor: 'pointer' }} variant="h4" color="primary" fontWeight="bold" component="div" onClick={() => navigate('/dashboard/propiedades')} >{'< Regresar'}</Typography>
            </Box>
            <Grid container >
                <Grid xs={12} md={12} lg={5} item >
                    {img !== '' ? (<img src={`${REACT_APP_HOST_URL}/images/${img}`} alt="Imagen de la casa" style={{ width: '100%' }} />) : <></>}
                    <Box display='flex' justifyContent='start' alignItems='center' marginTop='1rem'>

                        <Box display='flex' justifyContent='start' alignItems='center'>
                            <Typography variant='h5' > {current?.address?.mLand ?? 0} m<sup>2</sup></Typography>
                        </Box>

                        <Box display='flex' justifyContent='start' alignItems='center' ml='2.5rem'>
                            <Typography component={'p'}> {current?.address?.washroom ?? 0} </Typography>
                            <BathtubIcon color='primary' sx={{ fontSize: '50px', color: 'primary' }} />
                        </Box>

                        <Box display='flex' justifyContent='start' alignItems='center' ml='3rem'>
                            <Typography component={'p'}> {current?.address?.bedrooms ?? 0} </Typography>
                            <BedIcon color='primary' sx={{ fontSize: '50px' }} />
                        </Box>

                        <Box display='flex' justifyContent='start' alignItems='center' ml='3rem'>
                            <Typography component={'p'}> {current?.address?.parkingLot ?? 0} </Typography>
                            <DirectionsCarIcon color='primary' sx={{ fontSize: '50px' }} />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={6} marginLeft='1rem' padding='.4rem' >
                    <Box display='flex' justifyContent='space-between' alignItems='center' marginBottom='.5rem' >
                        <Typography variant="h5" color='primary'>Información del Cliente</Typography>
                        <Button onClick={onSubmit}>Asignar</Button>
                    </Box>
                    <Typography variant="h6" marginY='0.3rem'><b>Nombre: </b>{buyer?.name}</Typography>
                    <Typography variant="h6" marginY='0.3rem'><b>Correo: </b> {buyer?.phoneNumber}</Typography>
                    <Typography variant="h6" marginY='0.3rem'><b>Telefonó: </b>{buyer?.email}</Typography>
                    <Divider />
                    <Typography variant="h5" marginY='1rem' color='primary'>Información del Inmueble</Typography>
                    <Typography variant="h6" marginY='0.3rem'><b>Precio: </b>{formatCurrency(current?.offer)}</Typography>
                    <Typography variant="h6" marginY='0.3rem'><b>Id de la casa: </b>{current?._id}</Typography>
                    <Typography variant="h6" marginY='0.3rem'><b>Tipo: </b>{current?.type}</Typography>
                    <Typography variant="h6" marginY='0.3rem'><b>Numero de pisos: </b>{current?.address?.levels ?? 'N/A'}</Typography>
                    {current?.type === 'Departamento' && (<Typography variant="h6" marginY='0.3rem'><b>Piso: </b> {current?.address?.floor ?? 'N/A'}</Typography>)}
                    <Typography variant="h6" marginY='0.3rem'><b>Estado: </b>{current?.state}</Typography>
                    <Typography variant="h6" marginY='0.3rem'><b>Ubicación: </b>{`${current?.address?.street ?? ''} ${current?.address?.numExt ?? ''} ${current?.settlement ?? ''}`}</Typography>
                    <Typography variant="h6" marginY='0.3rem'><b>Asesor asignado: </b>{current?.saleAdviserRef?.name}</Typography>
                    <Divider />
                    <Typography variant="h5" marginY='1rem' color='primary'>Incluye</Typography>
                    {current.address?.spaceBetween && (<Typography variant="h6" marginY='0.3rem'><b>Espacio para sala y comendor</b></Typography>)}
                    {current.address?.kitchen && (<Typography variant="h6" marginY='0.3rem'><b>Cocina con tarja</b></Typography>)}
                    {current.address?.halfBath && (<Typography variant="h6" marginY='0.3rem'><b>Medio baño</b></Typography>)}
                    {current.address?.accessControl && (<Typography variant="h6" marginY='0.3rem'><b>Control de acceso y circuito</b></Typography>)}
                    {current.address?.heater && (<Typography variant="h6" marginY='0.3rem'><b>Calentador</b></Typography>)}
                    {current.address?.rooftop && (<Typography variant="h6" marginY='0.3rem'><b>Zotehuela</b></Typography>)}
                </Grid>
            </Grid>

        </>
    )
}
