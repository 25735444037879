import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Divider, Grid } from '@mui/material';
import { updateCurrentNotification } from '../../../actions/notificationsActions';
import React from 'react';
import DatesInfo from '../../../components/DateInfo';
import moment from 'moment';


const formateDate = (date) => {

    const options = {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
    }

    return new Date(date).toLocaleDateString('es-ES', options) + ' a las ' + moment(date).format('hh:mm a');
}

export const ListNotification = () => {


    const dispatch = useDispatch();
    const { notifications, currentNotification } = useSelector(state => state.notifications);


    const handleClick = (notification) => {
        dispatch(updateCurrentNotification({ notification, query: {} }))
    }


    return (
        <Grid container marginTop='3rem' paddingX='2rem' flexDirection={{ xs: 'column-reverse', md: 'row' }} spacing={1} >

            <Grid item xs={12} md={6} sx={{ height: '650px', overflow: 'auto' }}  >
                {
                    notifications.map(notification => (
                        <React.Fragment key={notification?._id}>
                            <Box paddingY='1rem' onClick={() => handleClick(notification)} sx={{ cursor: 'pointer', '&:hover': { background: '#e4dfdf33' } }} >
                                <Box display='flex' justifyContent='space-between' alignItems='center' paddingX='1rem' >
                                    <Typography variant='h6' fontWeight='bold' >
                                        {notification?.title}  {notification.seen === false && (<span style={{ color: '#F1592A', fontSize: '14px' }} >(nueva)</span>)}
                                    </Typography>
                                    <Typography variant='subtitle2' sx={{ color: '#718195' }} >
                                        {formateDate(notification?.createdAt)}
                                    </Typography>

                                </Box>
                                <Typography sx={{ color: '#718195' }} variant='subtitle1' paddingX='1rem' >
                                    {notification?.body?.substring(0, 30)}...
                                </Typography>
                            </Box>
                            <Divider />
                        </React.Fragment>
                    ))
                }
            </Grid>

            <Divider />

            <Grid item xs={12} md={5} padding='0 1rem'>
                {currentNotification?._id && (
                    <>
                        <Typography variant='subtitle2' fontWeight='bold' textAlign='right' marginBottom='.6rem' >
                            {formateDate(currentNotification?.createdAt)}
                        </Typography>

                        <Typography variant='h4' color='primary' paddingX='1rem' fontWeight='bold' marginBottom='.6rem' >
                            {currentNotification?.title}
                        </Typography>

                        <Typography sx={{ color: '#718195' }} variant='subtitle1' paddingX='1rem' marginTop='2rem' >
                            {currentNotification?.body}
                        </Typography>
                    </>
                )}
            </Grid>
        </Grid>

    )
}

