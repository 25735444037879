import { combineReducers, configureStore } from '@reduxjs/toolkit';
import alertsSlice from '../slices/alertsSlice';
import authSlice from '../slices/authSlice';
import buyRequestsSlice from '../slices/buyRequestsSlice';
import reconversionsSlice from '../slices/reconversionsSlice';
import filesSlice from '../slices/filesSlice';
import errorsSlice from '../slices/errorsSlice';
import loadingSlice from '../slices/loadingSlice';
import advisersSlice from '../slices/advisersSlice';
import propertiesSlice from '../slices/propertiesSlice';
import notificationsSlice from '../slices/notificationsSlice';

const rootReducer = combineReducers({
  advisers: advisersSlice,
  alerts: alertsSlice,
  auth: authSlice,
  buyRequests: buyRequestsSlice,
  errors: errorsSlice,
  files: filesSlice,
  loading: loadingSlice,
  notifications: notificationsSlice,
  properties: propertiesSlice,
  reconversions: reconversionsSlice,
});

const store = configureStore({ reducer: rootReducer });

export default store;
