import { useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getMeNotifications } from '../../../actions/notificationsActions';
import { ListNotification } from './ListNotifications';
import { useNavigate } from 'react-router-dom';

export const NotificationPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getMeNotifications({ query: {} }));
    }, [])


    return (
        <Grid container>

            <Grid item xs={12} marginBottom='2rem' display='flex' justifyContent='space-between' alignItems='center' >
                <Typography sx={{ cursor: 'pointer' }} variant="h4" color="primary" fontWeight="bold" component="div" onClick={() => navigate('/dashboard/perfilado')} >{'< Regresar'}</Typography>
            </Grid>

            <Grid item xs={12} >
                <ListNotification />
            </Grid>
        </Grid>
    )
}
