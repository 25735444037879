import { Button, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../utils/currencyUtils";

export const PropertiesListItem = (props) => {
  const navigate = useNavigate();
  const updateHome = () => {};
  return (
    <TableRow>
      <TableCell>
        <b>{props?._id}</b>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          <b>{formatCurrency(props.offer)}</b>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          <b>{props?.saleAdviserRef?.name}</b>
        </Typography>
      </TableCell>
      <TableCell>
        <Button
          fullWidth
          onClick={() => navigate(`/dashboard/propiedades/${props?._id}`)}
        >
          Ver mas
        </Button>
        <Button onClick={updateHome}>Asignar casa</Button>
      </TableCell>
    </TableRow>
  );
};
