import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom';
import { getPropertiesBySearch } from '../../../actions/propertiesAction';
import { PropertiesListTable } from './PropertiesListTable'

export const Properties = () => {


    const dispatch = useDispatch();
    const [existBuyer, setExistBuyer] = useState(true);


    useEffect(() => {
        const infoClient = JSON.parse(localStorage.getItem('infoClient')) ?? null;

        if (infoClient === null) {
            return setExistBuyer(false);
        }

        dispatch(getPropertiesBySearch({ query: { ...infoClient?.queryParams }, postDispatch: () => { } }))
    }, []);


    return (
        <>
            {existBuyer ? <PropertiesListTable /> : <Navigate to='/dashboard/prospectos' />}
        </>
    )
}
