import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBuyRequestsListForFirm } from '../../../actions/buyRequestsActions';
import { EventFirm } from './EventFirm';
import { useParams } from 'react-router-dom';
import { TYPES_CALENDAR } from '../../../constants/buyRequestsConstants';
import { EventVisit } from './EventVisit';

const localizer = momentLocalizer(moment);


const mapEventFirm = ({ firmDate, ...rest }) => ({
    title: <EventFirm infoCliente={rest} />,
    tooltip: `De ${moment(firmDate).format("LT")} a ${(moment(firmDate).add(1, "h")).format("LT")}`,
    start: new Date(firmDate),
    end: new Date(moment(firmDate).add(1, "h"))
})

const mapEventVisit = ({ visitDate, ...rest }) => ({
    title: <EventVisit infoCliente={rest} />,
    tooltip: `De ${moment(visitDate).format("LT")} a ${(moment(visitDate).add(1, "h")).format("LT")}`,
    start: new Date(visitDate),
    end: new Date(moment(visitDate).add(1, "h"))
})


export const CalendarPage = () => {


    const { term } = useParams();
    const dispatch = useDispatch();
    const buyRequests = useSelector(state => state.buyRequests.listForFirm);

    useEffect(() => {
        dispatch(getBuyRequestsListForFirm({ term: TYPES_CALENDAR[term] }))
    }, []);


    return (
        <>
            <Calendar
                localizer={localizer}
                events={buyRequests.map(term === 'firma' ? mapEventFirm : mapEventVisit)}
                startAccessor="start"
                endAccessor="end"
                tooltipAccessor={"tooltip"}
                views={['month', 'day', 'agenda']}
                style={{ height: 600, width: '90%', margin: '30px auto' }}
            />
        </>
    )
}
