import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, TableCell, TableRow, Typography, TextField, MenuItem } from '@mui/material';
import { deleteLead, updateCurrentReconversion } from '../../../actions/reconversionsActions';
import { ReconversionReassign } from './ReconversionReassign';
import { useModal } from '../../../hooks/useModal';
import AppActionButton from '../../../components/AppActionButton';
import { BUY_REQUESTS_STATUS } from '../../../constants/buyRequestsConstants';
import { ReconversionOffer } from './ReconversionOffer';

const ReconversionListItem = ({ buyRequestRef, ...props }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalReconversionReassing = useModal();
  const modalReconversionOffer = useModal();

  const address = `${buyRequestRef?.propertyRef?.address?.street ?? ''} ${buyRequestRef?.propertyRef?.address?.numExt ?? ''} ${buyRequestRef?.propertyRef?.settlement ?? ''}`;
  const lastCall = buyRequestRef?.callsRef[buyRequestRef.callsRef.length - 1];

  const handleDeleteLead = () => {
    dispatch(deleteLead({
      buyRequest_id: buyRequestRef?._id,
      buyRequest: { showLead: false },
      query: { status: props.statusPage }
    }))
  }


  const updateBuyRequest = (reconversion) => {
    dispatch(updateCurrentReconversion({
      id: props._id,
      reconversion: { ...reconversion },
      query: { status: props.statusPage }
    }))
  }




  return (
    <>

      <TableRow>
        <TableCell width='8%' ><Typography variant="body2"><b>{buyRequestRef?.status}</b></Typography></TableCell>
        <TableCell><Typography variant="body2"><b>{props?.user?.name}</b></Typography></TableCell>
        <TableCell>
          <Typography variant="body2"><b>Nombre:</b> {buyRequestRef?.buyerRef?.name}</Typography>
          <Typography variant="body2"><b>Correo:</b> {buyRequestRef?.buyerRef?.email}</Typography>
          <Typography variant="body2"><b>Teléfono:</b> {buyRequestRef?.buyerRef?.phoneNumber}</Typography>
          <Typography variant="body2"><b>Tipo de cŕedito:</b> Infonavit</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2"><b>Estado:</b> {buyRequestRef?.propertyRef?.state}</Typography>
          <Typography variant="body2"><b>Ubicación:</b> {address}</Typography>
          <Typography variant="body2"><b>Asesor asignado:</b> {buyRequestRef?.adviserRef?.name ?? buyRequestRef?.propertyRef?.saleAdviserRef?.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1"><b>Por {lastCall?.type} el <br /> {moment(lastCall?.date).format('YYYY-MM-DD')}</b></Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2"><b>{props?.reason}:</b><br /> {props?.reasonDetail}</Typography>
        </TableCell>
        <TableCell>
          <Grid container>
            <Grid item xs={12}>
              <AppActionButton fullWidth onClick={modalReconversionReassing.onOpenModal}>Reasignar</AppActionButton>
            </Grid>
            <Grid item xs={12}>
              <AppActionButton fullWidth onClick={() => navigate(`/dashboard/historial/${buyRequestRef?._id}`)}>
                Historial
              </AppActionButton>
            </Grid>
            {
              buyRequestRef?.status === BUY_REQUESTS_STATUS.PROSPECTS
              &&
              <Grid item xs={12}>
                <AppActionButton fullWidth onClick={modalReconversionOffer.onOpenModal}>Nueva oferta</AppActionButton>
              </Grid>
            }
            <Grid item xs={12}>
              <AppActionButton fullWidth color="secondary" onClick={handleDeleteLead}>Eliminar lead</AppActionButton>
            </Grid>
            <Grid item xs={12}>
              <TextField select label='Marcar como' fullWidth value={props?.status ?? ''} onChange={e => updateBuyRequest({ status: e.target.value })}>
                <MenuItem value='' disabled >-- Seleccione --</MenuItem>
                <MenuItem value={'Pendiente'} >Pendiente</MenuItem>
                <MenuItem value={'Cancelado'} >Cancelado</MenuItem>
                <MenuItem value={'Acciones en revision'} >Acciones en revison</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <ReconversionReassign open={modalReconversionReassing.open} onClose={modalReconversionReassing.onCloseModal} buyRequest_id={buyRequestRef?._id} statusPage={props.statusPage} />
      <ReconversionOffer open={modalReconversionOffer.open} onClose={modalReconversionOffer.onCloseModal} buyRequestRef={buyRequestRef} {...props} />
    </>
  );
}

export default ReconversionListItem;
