import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMeUser, loginUser, logoutUser, setFcmToken } from "../api/authApi";
import { clearToken, setToken, setUser } from "../slices/authSlice";
import { handleError } from "../slices/errorsSlice";
import { startLoading, endLoading } from "../slices/loadingSlice";

export const login = createAsyncThunk(
  "auth/login",
  ({ email, password, userType, callback = () => {} }, { dispatch }) => {
    dispatch(startLoading());

    loginUser({ email, password, userType })
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => {
        dispatch(setToken({ token: data.token }));
        window.localStorage.setItem("token", data.token);
        window.localStorage.setItem("type", userType);
        return data.token;
      })
      .then((token) => getMeUser(token, userType))
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => dispatch(setUser({ user: data?.adviser ?? data?.admin })))
      .then(() => callback())
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  ({ callback = () => {} }, { getState, dispatch }) => {
    const { token } = getState().auth;
    const userType = localStorage.getItem("type");

    dispatch(startLoading());

    logoutUser(token, userType)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then(() => {
        dispatch(clearToken());
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("type");
      })
      .then(() => callback())
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const setFcmTokenUser = createAsyncThunk(
  "auth/setFcmToken",
  ({ fcmToken }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    setFcmToken(token, fcmToken)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const getMeCurrentUser = createAsyncThunk(
  "auth/getMe",
  (payload, { getState, dispatch }) => {
    const { token } = getState().auth;
    const userType = localStorage.getItem("type");

    dispatch(startLoading());

    getMeUser(token, userType)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => dispatch(setUser({ user: data?.adviser ?? data?.admin })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);
