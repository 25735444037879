import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


export const Notification = () => {
    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={7000}
                // hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ height: '30rem' }}
            />
        </div>
    )
}
