import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';

import { login/* , loginExternalToken */ } from '../../actions/authActions';
import { USER_TYPES_LOGIN } from '../../constants/usersConstants';
import queryString from 'query-string';

const styles = {
  container: {
    height: '100vh'
  },
  bgImage: {
    backgroundImage: 'url(/img/login.jpeg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh'
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    marginBottom: '2rem'
  }
}

const Login = () => {
  const [state, setState] = useState({ email: '', password: '', userType: USER_TYPES_LOGIN.Admin });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(login({ ...state, callback: handleLogin }));
  }

  const handleLogin = () => {
    navigate('/dashboard');
  }

  const { token = '', userType = '' } = queryString.parse( location.search ); 
  console.log({token, userType});

  useEffect(() => {
    if(!token){
      console.warn('No user token');
      return;
    }
    if(!userType){
      console.warn('No user userType');
      return;
    }
    window.localStorage.setItem('token',token);
    window.localStorage.setItem('type',userType);
    console.log('Token and userType Save');
    /* dispatch(loginExternalToken({token, userType, callback:handleLogin})); */
  }, [token, userType])

  return (
    <Box sx={styles.container}>
      <Grid container>
        <Grid item xs={12} md={6} sx={styles.form}>
          <Box sx={{ px: { xs: '2rem', md: '5rem' } }}>
            <img alt="CTC Logo" src="/img/ctclogo.svg" style={styles.logo} />
            <Grid container padding={2} component="form" onSubmit={handleSubmit}>
              <Grid item xs={12} textAlign="center" marginBottom={2}>
                <Typography variant="h4">Inicia sesión</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField label="Correo" name="email" value={state.email} onChange={handleChange} color='secondary' />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Contraseña" type="password" name="password" value={state.password} onChange={handleChange}  color='secondary'/>
              </Grid>
              <Grid item xs={12}>
                <TextField select label="Nivel" name='userType' value={state.userType} onChange={handleChange}   color='secondary' >
                  <MenuItem value={USER_TYPES_LOGIN.Admin}>Administrador</MenuItem>
                  <MenuItem value={USER_TYPES_LOGIN.Adviser}>Asesor</MenuItem>
                  <MenuItem value={USER_TYPES_LOGIN.Leader}>Lider</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button type="submit">Entrar</Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={0} md={6} sx={styles.bgImage}></Grid>
      </Grid>
    </Box>
  );
}

export default Login;
