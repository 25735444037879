const { REACT_APP_API_BASE_URL } = process.env;

export const createCall = (token, call) => new Promise((resolve, reject) => {

    const request = new Request(REACT_APP_API_BASE_URL + '/calls/buyers', {
        method: 'POST',
        body: JSON.stringify({ call }),
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    })

    fetch(request)
        .then(response => resolve(response))
        .catch(error => reject(error))
})