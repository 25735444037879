import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';


const firebaseConfig = {
  apiKey: "AIzaSyAsYFmV8nLmt9On0uGWb1ONVml9bQ3QyZw",
  authDomain: "compramostucasa-edad2.firebaseapp.com",
  projectId: "compramostucasa-edad2",
  storageBucket: "compramostucasa-edad2.appspot.com",
  messagingSenderId: "275764094431",
  appId: "1:275764094431:web:5be45eda72374e8b25d06a",
  measurementId: "G-SFKGQPWD46"
};


// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
const firebaseAnalytics = getAnalytics(firebaseApp);


export {
  firebaseApp,
  firebaseAuth,
  firebaseAnalytics,
}











