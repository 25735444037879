import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BUY_REQUESTS_STATUS } from '../../../constants/buyRequestsConstants';
import FileListTable from './FileListTable';
import { getBuyRequestsList } from '../../../actions/buyRequestsActions';



const File = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBuyRequestsList({ query: { status: BUY_REQUESTS_STATUS.FILE } }));
  }, []);

  return (
    <>
      <FileListTable />
    </>
  );
}

export default File;
