import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { createNewReconversion } from "../actions/reconversionsActions";
import { optionsReconversion } from "../constants/reconversionsConstants";

const styles = {
    container: {
        display: 'block',
    },
    content: {
        paddingY: 0,
    },
    actions: {
        paddingTop: 0,
        paddingRight: '1rem'
    },
    title: {
        my: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    fieldWrapper: {
        px: '1rem'
    }
}


export const ModalReconversion = (props) => {

    const dispatch = useDispatch();
    const [data, setData] = useState({ reason: '', reasonDetail: '', date: '', type: '' });
    const isDisabled = useMemo(() => Object.values(data).includes(''), [data])

    const handleChange = (e) => setData(prev => ({ ...prev, [e.target.name]: e.target.value }));

    const handleClose = (e) => {
        e.preventDefault();
        props.onClose();
        setData({ reason: '', reasonDetail: '', date: '', type: '' });
    }


    const onHandleSubmit = () => {
        dispatch(createNewReconversion({
            id: props._id,
            buyRequest: { ...data },
            query: { status: props.query }
        }));

        props.onClose();
    }



    return (
        <Dialog fullWidth open={props.open} onClose={handleClose}>
            <DialogTitle>
                <Box sx={styles.title}>
                    <Typography variant="h5">Comentario</Typography>
                    <IconButton onClick={handleClose}><Close /></IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent sx={styles.content}>

                <Grid container>
                    <Grid item xs={12}>
                        <TextField  select label="Razón" name="reason" value={data.reason} onChange={handleChange} color='secondary'>
                            {optionsReconversion.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            rows={10}
                            variant="outlined"
                            placeholder="Comentario"
                            name="reasonDetail"
                            label='Comentario'
                            value={data.reasonDetail}
                            onChange={handleChange}
                            color='secondary'
                        />
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField select label='Forma de contacto' name='type' value={data.type} onChange={handleChange} color='secondary' >
                                <MenuItem value={'sms'} >SMS</MenuItem>
                                <MenuItem value={'telefono'} >Llamada Telefónica</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField label="Fecha" type="datetime-local" name="date" value={data.date} onChange={handleChange}  color='secondary'/>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={styles.actions}>
                <Button onClick={onHandleSubmit} disabled={isDisabled} >Enviar a reconversión</Button>
                <Button onClick={handleClose} color="secondary">Cancelar</Button>
            </DialogActions>
        </Dialog>
    )
}

