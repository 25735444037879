import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Profiling from "./pages/Dashboard/Profiling/Profiling";
import Prospects from "./pages/Dashboard/Prospects/Prospects";
import Reconversion from "./pages/Dashboard/Reconversion/Reconversion";
import File from "./pages/Dashboard/File/File";
import Signing from "./pages/Dashboard/Signing/Signing";
import { CalendarPage } from "./pages/Dashboard/Calendar/CalendarPage";
import HistoryPage from "./pages/Dashboard/History/HistoryPage";
import { Property } from "./pages/Dashboard/Property/Property";
import { Properties } from "./pages/Dashboard/Property/Properties";
import { NotificationPage } from "./pages/Dashboard/Notifications/NotificationPage";
import Deed from "./pages/Dashboard/Deed/Deed";

const Router = () => {
  const token = useSelector((state) => state.auth.token);

  return (
    <BrowserRouter>
      <Routes>
      <Route
          path="/healthz"
          element={<h1>Hey There!!! The App is Healthy</h1>}
        ></Route>
        <Route
          path="/login"
          element={token ? <Navigate to="/dashboard/perfilados" /> : <Login />}
        />
        <Route
          path="/dashboard/*"
          element={token ? <Dashboard /> : <Navigate to="/login" />}
        >
          <Route index element={<Navigate to="perfilados" />} />
          <Route path="perfilados" element={<Profiling />} />
          <Route path="prospectos" element={<Prospects />} />
          <Route path="reconversion" element={<Reconversion />} />
          <Route path="expediente" element={<File />} />
          <Route path="firma" element={<Signing />} />
          <Route path="escrituracion" element={<Deed />} />

          <Route path="notificaciones" element={<NotificationPage />} />
          <Route path="propiedades" element={<Properties />} />
          <Route path="propiedades/:id" element={<Property />} />
          <Route path="calendario/:term" element={<CalendarPage />} />
          <Route path="historial/:id" element={<HistoryPage />} />
          <Route path="*" element={<Navigate to="" />} />
        </Route>
        <Route
          path="*"
          element={
            token ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
