export const BUY_REQUESTS_STATUS = {
  PROFILING: 'Perfilado',
  PROSPECTS: 'Prospectos',
  FILE: 'Expediente',
  SIGNING: 'Firma',
  DEED: 'Escrituracion',
  DELIVERY: 'Entrega',
}


export const TYPES_CALENDAR = {
  firma: 'firm',
  prospectos: 'visit'
}