import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Box, Button, DialogActions, TextField, Grid, Divider, Typography, IconButton, MenuItem } from '@mui/material';
import { updateCurrentBuyer } from '../../../actions/buyerActions';

const styles = {
  container: {
    display: 'block',
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  title: {
    my: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldWrapper: {
    px: '1rem'
  }
}

const ProfilingClientDetails = props => {
  const [buyer, setBuyer] = useState({
    name: '',
    fLastName: '',
    mLastName: '',
    birthday: '',
    curp: '',
    rfc: '',
    userInfonavit: '',
    passInfonavit: '',
    amountBuy: '',
    numSons: '',
    phoneNumber: '',
    email: '',
    married: '',
    communityProperty: '',
    spouseName: '',
    spouseFlastName: '',
    spouseMlastName: '',
  });

  const dispatch = useDispatch();

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  useEffect(() => setBuyer({
    ...buyer,
    ...props.buyer,
    birthday: props.buyer?.birthday?.slice?.(0, 10) ?? ''
  }), []);

  const handleChange = e => setBuyer({ ...buyer, [e.target.name]: e.target.value });

  const handleSave = e => {
    e.preventDefault();

    const data = {};

    for (const key in buyer) {
      buyer[key] !== '' ? data[key] = buyer[key] : null;
    }

    dispatch(updateCurrentBuyer({ id: buyer._id, buyer: data }));
  }

  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Información del cliente</Typography>
          <IconButton onClick={handleClose}><Close /></IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.container}>
          <Grid container>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="ID de la vivienda" value={props._id} disabled color='secondary' />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="Nombre" name="name" value={buyer.name} onChange={handleChange} color='secondary' />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="Apellido paterno" name="fLastName" value={buyer.fLastName} onChange={handleChange} color='secondary'/>
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="Apellido materno" name="mLastName" value={buyer.mLastName} onChange={handleChange} color='secondary' />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField type="date" label="Fecha de nacimiento" InputLabelProps={{ shrink: true }} name="birthday" value={buyer.birthday} onChange={handleChange} color='secondary' />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="CURP" name="curp" value={buyer.curp} onChange={handleChange} color='secondary' />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="RFC" name="rfc" value={buyer.rfc} onChange={handleChange} color='secondary' />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField type="number" label="Monto autorizado de compra" name="amountBuy" value={buyer.amountBuy} onChange={handleChange} color='secondary' />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="Usuario INFONAVIT" name="userInfonavit" value={buyer.userInfonavit} onChange={handleChange}  color='secondary'/>
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="Contraseña INFONAVIT" name="passInfonavit" value={buyer.passInfonavit} onChange={handleChange} color='secondary' />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="Número de hijos" name="numSons" value={buyer.numSons} onChange={handleChange} color='secondary'/>
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="Teléfono" name="phoneNumber" value={buyer.phoneNumber} onChange={handleChange} color='secondary' />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="Correo" name="email" value={buyer.email} onChange={handleChange} color='secondary'/>
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField select label="Estado Civil" name='married' value={buyer.married} onChange={handleChange} color='secondary'>
                <MenuItem value={true}>Casado(a)</MenuItem>
                <MenuItem value={false}>Soltero(a)</MenuItem>
              </TextField>
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField select label="Bienes Mancomunados" name='communityProperty' value={buyer.communityProperty} onChange={handleChange} disabled={!buyer.married === true}>
                <MenuItem value={true}>Si</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <DialogTitle>
            <Box sx={styles.title}>
              <Typography variant="h5">Información del conyuge</Typography>
            </Box>
            <Divider />
          </DialogTitle>
          <Grid container>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="Nombres" name="spouseName" disabled={!buyer.communityProperty === true} value={buyer.spouseName} onChange={handleChange} />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="Apellido Paterno" name="spouseFlastName" disabled={!buyer.communityProperty === true} value={buyer.spouseFlastName} onChange={handleChange} />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="Apellido Materno" name="spouseMlastName" disabled={!buyer.communityProperty === true} value={buyer.spouseMlastName} onChange={handleChange} />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={handleSave}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProfilingClientDetails;
