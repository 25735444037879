import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    list: [],
    current: {},
    buyer: {},
    states: [],
    cities: [],
    settlements: [],
}

const propertiesSlice = createSlice({
    name: 'properties',
    initialState,
    reducers: {
        setPropertiesList: (state, { payload }) => ({ ...state, list: [...payload.properties] }),
        setPropertiesStates: (state, { payload }) => ({ ...state, states: [...payload.states] }),
        setPropertiesCities: (state, { payload }) => ({ ...state, cities: [...payload.cities] }),
        setPropertiesSettlements: (state, { payload }) => ({ ...state, settlements: [...payload.settlements] }),
        setPropertiesCurrent: (state, { payload }) => ({ ...state, current: { ...payload.property } }),
        setPropertiesBuyer: (state, { payload }) => ({ ...state, buyer: { ...payload.buyer } }),
        resetProperties: () => ({ list: [], states: [], cities: [], settlements: [], buyer: {} })
    }
});

export const { setPropertiesList, setPropertiesStates, setPropertiesCities, setPropertiesSettlements, setPropertiesCurrent, setPropertiesBuyer, resetProperties } = propertiesSlice.actions;
export default propertiesSlice.reducer;
