import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  user: {},
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (_, { payload }) => ({ token: payload.token }),
    setUser: (state, { payload }) => ({ ...state, user: payload.user, }),
    clearToken: () => ({ token: null, user: {} })
  }
});

export const { setToken, clearToken, setUser } = authSlice.actions;
export default authSlice.reducer;
