import { Typography } from '@mui/material'
import React from 'react'


export const EventVisit = (props) => {

    const { buyerRef, propertyRef } = props?.infoCliente
    const address = `${propertyRef?.address?.street ?? ''} ${propertyRef?.address?.numExt ?? ''} ${propertyRef?.settlement ?? ''}`;

    return (
        <Typography variant='body1'>
            <b>Cliente:</b>{buyerRef?.name},{' '}
            <b>Ubicación:</b> {address.toLocaleUpperCase()},{' '}
            <b>Asesor: </b> {propertyRef?.saleAdviserRef?.name}
        </Typography>
    )
}
