import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  DialogActions,
  TextField,
  Grid,
  Divider,
  Typography,
  IconButton,
  MenuItem,
  Autocomplete,
  Alert,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  getAllCitiesByState,
  getAllSettlementsByState,
  getAllStatesActive,
  getPropertiesBySearch,
} from "../../../actions/propertiesAction";
import { resetProperties } from "../../../slices/propertiesSlice";

const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingTop: 0,
    paddingRight: "1rem",
  },
  title: {
    my: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrapper: {
    px: "1rem",
  },
};

const ProspectsSearchHouse = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { states, cities, settlements } = useSelector(
    (state) => state.properties
  );
  const [alert, setAlert] = useState("");
  const [filtro, setFiltro] = useState({
    state: "",
    city: "",
    settlement: "",
    division: "" /** fraccionamineto **/,
    mLand: "",
    bedrooms: "",
  });

  const handleChange = (e) =>
    setFiltro((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  useEffect(() => {
    if (props.open) {
      dispatch(getAllStatesActive({ query: { active: "yes" } }));
    }
  }, [props.open]);

  useEffect(() => {
    if (filtro.state !== "") {
      dispatch(getAllCitiesByState({ query: { state: filtro.state } }));
    }
  }, [filtro.state]);

  useEffect(() => {
    if (filtro.city !== "" && filtro.city !== null) {
      dispatch(
        getAllSettlementsByState({
          query: { state: filtro.state, city: filtro.city },
        })
      );
    }
  }, [filtro.city]);

  const handleSubmit = () => {
    setAlert("");

    const queryParams = getQueryParams(filtro);

    dispatch(
      getPropertiesBySearch({ query: { ...queryParams }, postDispatch })
    );
  };

  const postDispatch = (properties) => {
    if (properties.length === 0)
      return showAlert("No se encontrarón casas con esas caracteristicas.");

    const infoClient = {
      buyRequestRef: props?._id,
      buyerRef: props.buyerRef?._id,
      queryParams: getQueryParams(filtro),
    };

    window.localStorage.setItem("infoClient", JSON.stringify(infoClient));

    return navigate("/dashboard/propiedades");
  };

  const showAlert = (msg) => {
    setAlert(msg);
    setTimeout(() => setAlert(""), 55000);
  };

  const handleClose = (e) => {
    props.onClose();
    dispatch(resetProperties());
  };

  const getQueryParams = (queries) => {
    const queryParams = {};

    for (const key in filtro) {
      filtro[key] !== "" && filtro[key] !== null
        ? (queryParams[key] = filtro[key])
        : null;
    }

    return queryParams;
  };

  return (
    <Dialog fullWidth open={props.open} onClose={handleClose}>
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Buscar casa</Typography>
          {alert !== "" && (
            <Alert variant="outlined" severity="error" sx={{ mr: "1rem" }}>
              {" "}
              {alert}
            </Alert>
          )}
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.container}>
          <Grid container>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField
                select
                label="¿Qué zona busca?"
                name="state"
                value={filtro.state}
                onChange={handleChange}
                color="secondary"
              >
                {states.map((state) => (
                  <MenuItem key={state._id} value={state.state}>
                    {state.state}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <Autocomplete
                disabled={!cities.length > 0}
                freeSolo
                options={cities}
                onChange={(e, newValue) =>
                  setFiltro((prev) => ({ ...prev, city: newValue }))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Municipio ó Delegación"
                    color="secondary"
                  />
                )}
              />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <Autocomplete
                disabled={!settlements.length > 0}
                freeSolo
                options={settlements}
                onChange={(e, newValue) =>
                  setFiltro((prev) => ({ ...prev, settlement: newValue }))
                }
                renderInput={(params) => (
                  <TextField {...params} label="Colonia" color="secondary" />
                )}
              />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField
                label="Fraccionamiento"
                name="division"
                value={filtro.division}
                onChange={handleChange}
                color="secondary"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="secondary" variant="h6" sx={{ my: "1rem" }}>
                Características de la casa
              </Typography>
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField
                label="Metros cuadrados"
                name="mLand"
                value={filtro.mLand}
                onChange={handleChange}
                color="secondary"
              />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField
                label="Habitaciones"
                name="bedrooms"
                value={filtro.bedrooms}
                onChange={handleChange}
                color="secondary"
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={handleSubmit} disabled={!filtro.state}>
          Buscar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProspectsSearchHouse;
