const { REACT_APP_API_BASE_URL } = process.env;




export const getAllNotification = (token, query) => new Promise((resolve, reject) => {

    const queryParams = new URLSearchParams({ ...query }).toString();

    const request = new Request(REACT_APP_API_BASE_URL + '/notifications?' + queryParams, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    });

    fetch(request)
        .then(response => resolve(response))
        .catch(error => reject(error))
});




export const updateNotification = (token, id, notification) => new Promise((resolve, reject) => {


    const request = new Request(REACT_APP_API_BASE_URL + '/notifications/' + id, {
        method: 'PUT',
        body: JSON.stringify({ notification }),
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    });

    fetch(request)
        .then(response => resolve(response))
        .catch(error => reject(error))
});



