const { REACT_APP_API_BASE_URL } = process.env;






export const getAllAdvisers = (token, query) => new Promise((resolve, reject) => {

    const queryParams = new URLSearchParams({ ...query }).toString();

    const request = new Request(REACT_APP_API_BASE_URL + '/advisers?' + queryParams, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    })

    fetch(request)
        .then(response => resolve(response))
        .catch(error => reject(error))
})