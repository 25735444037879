import { Chip, Grid, TableCell } from '@mui/material';
import AppActionButton from '../../../components/AppActionButton';
import AppBuyRequestTile from '../../../components/AppBuyRequestTile';
import SigningDate from './SigningDate';
import { useModal } from '../../../hooks/useModal';
import { BUY_REQUESTS_STATUS } from '../../../constants/buyRequestsConstants';
import { ModalReconversion } from '../../../components/ModalReconversion';
import { updateCurrentBuyRequest } from '../../../actions/buyRequestsActions';
import { useDispatch } from 'react-redux';
import SiningDateDelivery from './SiningDateDelivery';
import SigningTitling from './SigningTitling';

const SigningListItem = props => {

  const dispatch = useDispatch();
  const modalSiging = useModal();
  const modalReconversion = useModal();
  const modalSigingDateDelivery = useModal();
  const modalSigingTitulado = useModal();

  const handleUpdateBuyRequest = (buyRequest) => {
    dispatch(updateCurrentBuyRequest({
      id: props._id,
      buyRequest: { ...buyRequest },
      query: { status: BUY_REQUESTS_STATUS.SIGNING }
    }));
  };



  return (
    <>
      <AppBuyRequestTile {...props}>
        <TableCell>
          <AppActionButton fullWidth onClick={modalSiging.onOpenModal}>Firma</AppActionButton>
          {
            props?.firm === true &&
            (
              <AppActionButton
                fullWidth
                onClick={modalSigingDateDelivery.onOpenModal}
                disabled={!props?.propertyRef?.screenTitling}
              >
                Entrega
              </AppActionButton>
            )
          }
        </TableCell>
        <TableCell>
          <Grid container>
            <Grid item xs={12}>
              {
                props?.firm === true
                  ? <Chip variant='outlined' color='success' label='FIRMADO' sx={{ width: '100%' }} />
                  : <AppActionButton
                    fullWidth
                    disabled={!props?.firmDate}
                    onClick={() => handleUpdateBuyRequest({ firm: true })}
                  >
                    Firmado
                  </AppActionButton>
              }
            </Grid>
            <Grid item xs={12}>
              <AppActionButton fullWidth color="secondary" disabled={!props?.firmDate} onClick={modalReconversion.onOpenModal}>No firmado</AppActionButton>
            </Grid>
            <Grid item xs={12}>
              <AppActionButton
                fullWidth
                onClick={modalSigingTitulado.onOpenModal}
                disabled={!props.firm}
              >
                Titulado
              </AppActionButton>
            </Grid>
          </Grid>
        </TableCell>
      </AppBuyRequestTile>
      <ModalReconversion open={modalReconversion.open} onClose={modalReconversion.onCloseModal} _id={props._id} query={BUY_REQUESTS_STATUS.SIGNING} />
      <SigningDate open={modalSiging.open} onClose={modalSiging.onCloseModal} {...props} />
      <SiningDateDelivery open={modalSigingDateDelivery.open} onClose={modalSigingDateDelivery.onCloseModal} {...props} />
      <SigningTitling open={modalSigingTitulado.open} onClose={modalSigingTitulado.onCloseModal} {...props} />
    </>
  );
}

export default SigningListItem;
