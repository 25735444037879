import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getBuyRequestsList } from "../../../actions/buyRequestsActions";
import { BUY_REQUESTS_STATUS } from "../../../constants/buyRequestsConstants";
import DeedListTable from "./DeedListTable";

const Deed = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getBuyRequestsList({ query: { status: BUY_REQUESTS_STATUS.DEED } })
    );
  }, []);

  return <DeedListTable />;
};

export default Deed;
