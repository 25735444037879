import { Close } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../slices/alertsSlice";
import {
  updateCurrentBuyRequest,
  updateUploadedBuyRequests,
} from "../../../actions/buyRequestsActions";
import { BUY_REQUESTS_STATUS } from "../../../constants/buyRequestsConstants";

// import { getAllBuyRequests } from "../../api/buyRequest/getAllBuyRequest";
// import { updateCurrentBuyRequest } from "../../api/buyRequest/updateBuyRequest";
// import { uploadedBuyRequests } from "../../api/buyRequest/uploadedBuyRequest";
// import Loading from "../../components/loading/Loading";
// import { useDeedContext } from "../../hooks/useDeedContext";
const { REACT_APP_DOCS_URL } = process.env;
const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingTop: 0,
    paddingRight: "1rem",
  },
  title: {
    my: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrapper: {
    px: "1rem",
  },
};
export const DeedEvaluate = (props) => {
  const dispatch = useDispatch();
  // const [error, setError] = useState("");
  // const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [pathEvaletion, setPathEvaletion] = useState(
    props.pathEvaletion ?? null
  );
  const [amountEvaletion, setAmountEvaletion] = useState(
    props.amountEvaletion ?? ""
  );
  const [authorizedEvaletion, setAuthorizedEvaletion] = useState(
    props.authorizedEvaletion ?? ""
  );

  const handleClose = () => props.onClose();

  const onSubmit = () => {
    if (
      amountEvaletion === "" ||
      authorizedEvaletion === "" ||
      selectedFile === null
    ) {
      dispatch(
        showAlert({
          message: "Todos los campos son obligatorios",
          type: "error",
        })
      );
      return;
    }
    //TODO: Integrar la subida de archivos (buyRequestApi.js) y el action (buyRequestsActions.js)
    dispatch(
      updateCurrentBuyRequest({
        id: props._id,
        buyRequest: { amountEvaletion, authorizedEvaletion },
        query: { status: BUY_REQUESTS_STATUS.DEED },
      })
    );
    dispatch(
      updateUploadedBuyRequests({
        id: props._id,
        buyRequest: selectedFile,
      })
    );
  };

  return (
    <Dialog fullWidth open={props.open} onClose={handleClose}>
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Monto del Avalúo</Typography>

          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.container}>
          <TextField
            label="Monto"
            type="number"
            min={0}
            onChange={(e) => setAmountEvaletion(e.target.value)}
            value={amountEvaletion}
          />
          <TextField
            label="Aprobó"
            value={authorizedEvaletion}
            onChange={(e) => setAuthorizedEvaletion(e.target.value)}
            min={0}
          />
          <TextField
            id=""
            type="file"
            label=""
            onChange={(e) => setSelectedFile(e.target.files[0])}
          />
          {pathEvaletion && (
            <Box width={"100%"}>
              <label htmlFor="" className="text-start w-100">
                Comprobante <br />
                <a
                  href={`${REACT_APP_DOCS_URL}/${props.pathEvaletion}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.pathEvaletion}
                </a>
              </label>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={onSubmit}>Guardar</Button>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};
