import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DvrIcon from "@mui/icons-material/Dvr";
import { Box, Drawer, List, ListItem } from "@mui/material";
import {
  AddHomeOutlined,
  AssignmentOutlined,
  AssignmentReturnOutlined,
  ContactPageOutlined,
  FolderSharedOutlined,
  Logout,
} from "@mui/icons-material";

import { logout } from "../actions/authActions";
import AppSidebarTile from "./AppSidebarTile";

const items = [
  {
    text: "Perfilados",
    Icon: ContactPageOutlined,
    path: "/dashboard/perfilados",
  },
  { text: "Prospectos", Icon: AddHomeOutlined, path: "/dashboard/prospectos" },
  {
    text: "Reconversión",
    Icon: AssignmentReturnOutlined,
    path: "/dashboard/reconversion",
  },
  {
    text: "Integración de Expediente",
    Icon: FolderSharedOutlined,
    path: "/dashboard/expediente",
  },
  { text: "Escrituración", Icon: DvrIcon, path: "/dashboard/escrituracion" },
  { text: "Firma", Icon: AssignmentOutlined, path: "/dashboard/firma" },
];

const styles = {
  drawer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  logoWrapper: {
    justifyContent: "center",
    my: "0.9rem",
    flex: 0,
  },
  logo: {
    width: "80%",
    cursor: "pointer",
  },
  dividerWrapper: {
    marginY: "0.75rem",
    paddingX: "1rem",
  },
  buttonWrapper: {
    textAlign: "center",
    padding: "1rem",
  },
  items: {
    backgroundColor: "secondary.main",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    py: "1rem",
  },
};

const AppSidebar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = (path) => () => {
    navigate(path);
    props.onClose?.();
  };

  const handleLogout = () => {
    dispatch(logout(() => navigate("/login")));
  };

  return (
    <Drawer {...props}>
      <Box sx={styles.drawer} role="presentation">
        <ListItem sx={styles.logoWrapper}>
          <a href="https://dashboard.ctc-developers.com/ctc/dashboard/escrituracion">
            <img src="/img/ctclogo.svg" alt="logo" style={styles.logo} />
          </a>
        </ListItem>
        <List sx={styles.items}>
          {items.map((item) => (
            <AppSidebarTile
              key={item.text}
              onClick={handleNavigate(item.path)}
              {...item}
            />
          ))}
          <AppSidebarTile onClick={handleLogout} Icon={Logout} text="Salir" />
        </List>
      </Box>
    </Drawer>
  );
};

export default AppSidebar;
