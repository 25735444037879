import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';
import ReconversionListItem from './ReconversionListItem';

const ReconversionListTable = (props) => {
  const reconversions = useSelector(state => state.reconversions.list);



  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Origen</TableCell>
            <TableCell>Enviado por</TableCell>
            <TableCell>Información del cliente</TableCell>
            <TableCell>Información del inmueble</TableCell>
            <TableCell>Utimo Contacto</TableCell>
            <TableCell>Razones</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reconversions.map(reconversion => <ReconversionListItem key={reconversion._id} {...reconversion} {...props} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ReconversionListTable;
