import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: {},
  list: [],
  options: []
}

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setFilesList: (state, { payload }) => ({ ...state, list: [...payload.files] }),
    setFilesOptions: (state, { payload }) => ({ ...state, options: [...payload.options] }),
    setCurrentFile: (state, { payload }) => ({ ...state, current: { ...payload.file } }),
    resetFilesState: () => ({ list: [], current: { }, options: [] })
  }
});

export const { setFilesList, setCurrentFile, setFilesOptions, resetFilesState } = filesSlice.actions;
export default filesSlice.reducer;
