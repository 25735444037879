import moment from "moment";

moment.updateLocale('en', {
  relativeTime: {
    past: "%s",
    s: 'unos segundos',
    ss: '%d segundos',
    m: "un minuto",
    mm: "%d minutes",
    h: "una hora",
    hh: "%d horas",
    d: "un día",
    dd: "%d días",
    w: "una semana",
    ww: "%d semanas",
    M: "un mes",
    MM: "%d meses",
    y: "un año",
    yy: "%d años"
  }
});

const DatesInfo = (props) => {
  return (
    <div className='info-extend text-start value-title'>
      <label>{moment(props?.createdAt).format("D/MMM/YY") ?? ""}</label>
      <label>{moment(props?.createdAt).format("h:mm A") ?? ""}</label>
      <label>{moment(props?.createdAt).fromNow(true) ?? ""}</label>

    </div>
  );
}

export default DatesInfo;