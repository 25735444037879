import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReconversionListTable from './ReconversionListTable';
import { getReconversionsList, getReconversionsMetrics } from '../../../actions/reconversionsActions';
import { ReconversionMenu } from './ReconversionMenu';



const Reconversion = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('');

  useEffect(() => {
    dispatch(getReconversionsList({ query: { status } }));
  }, [status]);


  useEffect(() => {
    dispatch(getReconversionsMetrics());
  }, []);


  return (
    <>
      <ReconversionMenu status={status} setStatus={setStatus} />
      <ReconversionListTable statusPage={status} />
    </>
  );
}

export default Reconversion;
