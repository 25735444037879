import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getBuyRequestsList } from '../../../actions/buyRequestsActions';
import { BUY_REQUESTS_STATUS } from '../../../constants/buyRequestsConstants';
import SigningListTable from './SigningListTable';

const Signing = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBuyRequestsList({ query: { status: BUY_REQUESTS_STATUS.SIGNING } }));
  }, []);

  return (
    <>
      <SigningListTable />
    </>
  );
}

export default Signing;
