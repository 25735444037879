export const USER_TYPES = {
    Admin: 'Administrador',
    Adviser: 'Asesor',
    Seller: 'Vendedor',
    Buyer: 'Comprador',
    Lawyer: 'Abogado',
}

export const USER_TYPES_LOGIN = {
    Admin: 'admins',
    Adviser: 'advisers',
    Leader: 'leaders'
}