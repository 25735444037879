import {
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { updateCurrentDoc } from "../../../actions/filesActions";
// import { ButtonLoading } from "../../components/Deed/ButtonLoading";
// import TokenContext from "../../context/Token";

const { REACT_APP_DOCS_URL } = process.env;

export const DeedFileLink = (props) => {
  const dispatch = useDispatch();
  const [valid, setValid] = useState(props.valid ?? false);
  const [isDisabled, setIsDisabled] = useState(
    props.valid === true || props.valid === undefined ? true : false
  );

  const [comment, setComment] = useState(props.comment ?? "");

  const handleValid = async (e) => {
    if (e.target.value === "si") {
      dispatch(
        updateCurrentDoc({
          id: props._id,
          fileData: { valid: true, reUploaded: false, comment: "" },
        })
      );
      setValid(true);
    }

    if (e.target.value === "no") {
      setIsDisabled(false);

      setValid(false);
    }
  };

  const handleInvalid = () => {
    dispatch(
      updateCurrentDoc({ id: props._id, fileData: { valid: false, comment } })
    );
  };

  // useEffect(() => {
  //   setIsDisabled(props?.valid === false ? false : true);

  //   setValid(props?.valid ? props.valid : false);
  // }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <p className="deed-text text-center">
            {props.reUploaded && (
              <span className="text-primary">Corregido - </span>
            )}{" "}
            {" " + props.type}
          </p>
        </Grid>
        <Grid
          item
          xs={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <a
            href={`${REACT_APP_DOCS_URL}/${props.path}`}
            target="_blank"
            rel="noreferrer"
          >
            <IconButton color="primary" sx={{ m: "auto" }}>
              <AiFillEye fontSize={"20px"} color="inherit" />
            </IconButton>
          </a>
        </Grid>
        {valid === true ? (
          <Grid
            item
            xs={2}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Chip
              variant="outlined"
              color="success"
              label={"Válido"}
              sx={{ fontSize: "18px" }}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={2}>
              <TextField
                onChange={handleValid}
                select
                fullWidth
                label="Validar"
                size="small"
                defaultValue={props.valid === false ? "no" : ""}
              >
                <MenuItem value={"si"}>Si</MenuItem>
                <MenuItem value={"no"}>No</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                size="small"
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                className="form-control"
                label="Razón del rechazo"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={2} display={"flex"} justifyContent={"center"}>
              <Button
                className="deed-button"
                disabled={isDisabled}
                onClick={handleInvalid}
              >
                Enviar
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Divider />
    </>
  );
};
