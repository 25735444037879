import { Delete } from '@mui/icons-material';
import { Chip, Grid, IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';
import { deleteCurrentFile } from '../../../actions/filesActions';

const { REACT_APP_HOST_URL } = process.env;

export const FileLinkDoc = (props) => {

    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(deleteCurrentFile({ id: props.doc._id }))
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <a className='link' href={`${REACT_APP_HOST_URL}/docs/${props.doc.path}`} target="_blank" rel="noreferrer" >
                        {props.doc.type} - Subido {new Date(props.doc.createdAt).toLocaleDateString()}
                    </a>
                </Grid>
                <Grid item xs={12} md={12}>
                    {props.doc?.valid === false && <Chip variant='outlined' color='error' label={props.doc?.comment} />}
                </Grid>
            </Grid>
            {
                props?.doc?.valid === false && (
                    <IconButton onClick={handleDelete}><Delete /></IconButton>
                )
            }
        </>
    )
}
