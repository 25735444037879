import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AttachFile, Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Box, Button, DialogActions, Grid, Divider, Typography, IconButton } from '@mui/material';
import { uploadPropertyTitling } from '../../../actions/buyRequestsActions';
import { BUY_REQUESTS_STATUS } from '../../../constants/buyRequestsConstants';

const { REACT_APP_HOST_URL } = process.env;

const styles = {
    container: {
        display: 'block',
    },
    content: {
        paddingY: 0,
    },
    actions: {
        paddingTop: 0,
        paddingRight: '1rem'
    },
    title: {
        my: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    fieldWrapper: {
        px: '1rem'
    },
    iconButton: {
        p: '2.5rem',
        borderStyle: 'dashed'
    },
}

const SigningTitling = props => {

    const dispatch = useDispatch();
    const inputFile = useRef(null);
    const [doc, setDoc] = useState(null);


    const handleFileChange = e => setDoc(e.target.files[0]);
    const handleOpenFile = () => inputFile.current.click();
    const handleClose = () => props.onClose();
    const postDispatch = () => {
        inputFile.current.value = null;
        setDoc(null);
    }

    const handleSubmit = () => {
        dispatch(uploadPropertyTitling({
            doc,
            id: props?.propertyRef?._id,
            query: { status: BUY_REQUESTS_STATUS.SIGNING },
            callback: postDispatch
        }));
    }


    return (
        <Dialog fullWidth open={props.open} onClose={handleClose}>
            <DialogTitle>
                <Box sx={styles.title}>
                    <Typography variant="h5">Titulación</Typography>
                    <IconButton onClick={handleClose}><Close /></IconButton>
                </Box>
                <Divider />
            </DialogTitle>
            <DialogContent sx={styles.content}>
                <Box sx={styles.container}>
                    <Grid container justifyContent='center' alignItems='center'>
                        <Grid item sx={styles.fieldWrapper} xs={12} md={6}>
                            <Button variant="outlined" color="primary" component="label" size="large" sx={styles.iconButton} onClick={handleOpenFile}>
                                <AttachFile />
                            </Button>
                            <input ref={inputFile} hidden accept="image/*" type="file" id='inputFile' onChange={handleFileChange} />
                            <Typography>{doc?.name ?? 'Seleccionar archivo'}</Typography>
                            <Button onClick={handleSubmit} disabled={!doc}>Subir</Button>
                        </Grid>
                        <Grid item sx={styles.fieldWrapper} xs={12} md={6}>
                            {
                                props?.propertyRef?.screenTitling &&
                                (
                                    <a className='link' href={`${REACT_APP_HOST_URL}/docs/${props?.propertyRef?.screenTitling}`} target="_blank" rel="noreferrer" >
                                        Titulacion
                                    </a>
                                )
                            }
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={styles.actions}>
                <Button onClick={handleClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default SigningTitling;
