import { useSelector } from 'react-redux'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { PropertiesListItem } from './PropertiesListItem';


export const PropertiesListTable = () => {

    const properties = useSelector(state => state.properties.list);


    


    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID de la casa</TableCell>
                        <TableCell>Precio</TableCell>
                        <TableCell>Asesor</TableCell>
                        <TableCell>Mas</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {properties.map(property => <PropertiesListItem key={property._id} {...property} />)}
                </TableBody>
            </Table>
        </TableContainer>
    )
}







