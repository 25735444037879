import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    list: [],
}




const advisersSlice = createSlice({
    name: 'advisers',
    initialState,
    reducers: {
        setAdviserList: (state, { payload }) => ({ ...state, list: [...payload.advisers] }),
        resetBuyRequestsState: () => ({ list: [] })
    }
});


export const { setAdviserList } = advisersSlice.actions;
export default advisersSlice.reducer;