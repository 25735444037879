import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography, IconButton } from '@mui/material';
import { getHistoryBuyRequest } from '../../../actions/buyRequestsActions';
import { ListHistory } from './ListHistory';
import IconPdf from '@mui/icons-material/PictureAsPdfOutlined';
const { REACT_APP_API_BASE_URL } = process.env;

const HistoryPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();


    useEffect(() => {
        dispatch(getHistoryBuyRequest({ query: { type: 'compra', reference: id } }))
    }, [])


    const handleDownloadPdf = () => {
        window.location = `${REACT_APP_API_BASE_URL}/pdf/history-property?reference=${id}`;
    }


    return (
        <Grid container>

            <Grid item xs={12} marginBottom='2rem' display='flex' justifyContent='space-between' alignItems='center' >
                <Typography sx={{ cursor: 'pointer' }} variant="h4" color="primary" fontWeight="bold" component="div" onClick={() => navigate('/dashboard/reconversion')} >{'< Regresar'}</Typography>
                <IconButton size='large' color='primary' sx={{ width: '2rem' }} onClick={handleDownloadPdf} >
                    <IconPdf />
                </IconButton>
            </Grid>

            <Grid item xs={12} >
                <ListHistory />
            </Grid>
        </Grid>
    )
}

export default HistoryPage