import { useDispatch } from 'react-redux';
import { Grid, TableCell } from '@mui/material';
import { BUY_REQUESTS_STATUS } from '../../../constants/buyRequestsConstants';
import AppActionButton from '../../../components/AppActionButton';
import AppBuyRequestTile from '../../../components/AppBuyRequestTile';
import ProfilingClientDetails from './ProfilingClientDetails';
import ProfilingCalculateCredit from './ProfilingCalculateCredit';
import { updateCurrentBuyRequest } from '../../../actions/buyRequestsActions';
import { ModalReconversion } from '../../../components/ModalReconversion';
import { useModal } from '../../../hooks/useModal';

const ProfilingListItem = props => {

  const dispatch = useDispatch();

  const modalReconversion = useModal();
  const modalClientDetails = useModal();
  const modalCalculateCredit = useModal();


  const handleQualified = () => {
    dispatch(updateCurrentBuyRequest({
      id: props._id,
      buyRequest: { status: BUY_REQUESTS_STATUS.PROSPECTS },
      query: { status: BUY_REQUESTS_STATUS.PROFILING }
    }));
  };



  return (
    <AppBuyRequestTile {...props}>
      <TableCell width='30%'>
        <Grid container>
          <Grid item xs={12}>
            <AppActionButton fullWidth onClick={modalClientDetails.onOpenModal}>Información del cliente</AppActionButton>
          </Grid>
          <Grid item xs={12}>
            <AppActionButton fullWidth onClick={modalCalculateCredit.onOpenModal}>Crédito</AppActionButton>
          </Grid>
          <Grid item xs={12}>
            <AppActionButton fullWidth onClick={handleQualified}>Calificado</AppActionButton>
          </Grid>
          <Grid item xs={12}>
            <AppActionButton fullWidth onClick={modalReconversion.onOpenModal} color="secondary">No Califica</AppActionButton>
          </Grid>
        </Grid>
      </TableCell>
      <ModalReconversion open={modalReconversion.open} onClose={modalReconversion.onCloseModal} _id={props._id} query={BUY_REQUESTS_STATUS.PROFILING} />
      <ProfilingClientDetails open={modalClientDetails.open} onClose={modalClientDetails.onCloseModal} _id={props._id} buyer={props.buyerRef} />
      <ProfilingCalculateCredit open={modalCalculateCredit.open} onClose={modalCalculateCredit.onCloseModal} _id={props?._id} credit={props?.creditRef ?? {}} />
    </AppBuyRequestTile>
  );
}

export default ProfilingListItem;
