import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: '#F1592A',
      dark: '#F69477',
      light: '#AA553B',
    },
    secondary: {
      main: '#718195',
      dark: '#8D9AAA',
      light: '#4F5A68',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    white: {
      main: '#FFF',
      dark: '#FFF',
      light: '#FFF',
      contrastText: '#000',
    },
    orange: {
      main: '#FFA500',
      dark: '#FFB733',
      light: '#B27300',
      contrastText: '#fff',
    },  
  },
  components: {
    MuiTextField: {
      defaultProps: {
        fullWidth: true
      },
      styleOverrides: {
        root: {
          marginTop: defaultTheme.spacing(1),
          marginBottom: defaultTheme.spacing(1)
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'orange',
      },
      styleOverrides: {
        root: {
          marginTop: defaultTheme.spacing(1.5),
          marginBottom: defaultTheme.spacing(1.5)
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: grey[200],
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#718195',
          fontWeight: 'bold'
        },
        body: {
          color: '#718195'
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.primary.main,
          color: defaultTheme.palette.primary.contrastText,
          textAlign: 'center'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: '80vw'
        }
      }
    }
  }
});

export default theme;
