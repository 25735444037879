import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Box, Button, DialogActions, TextField, Grid, Divider, Typography, IconButton, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createNewCredit } from '../../../actions/creditActions';

const styles = {
  container: {
    display: 'block',
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  title: {
    my: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldWrapper: {
    px: '1rem'
  }
}



const ProfilingCalculateCredit = props => {

  const dispatch = useDispatch();
  const [credit, setCredit] = useState({
    worked: '',
    timeWorking: '',
    monthlyMoney: '',
    otherCredits: '',
    economicDependents: '',
    numEconomicDependents: '',
    aprobado: '',
    amount: ''
  });

  const handleClose = () => props.onClose();
  const handleChange = (e) => setCredit(prev => ({ ...prev, [e.target.name]: e.target.value }))

  useEffect(() => {
    setCredit(prev => ({ ...prev, ...props.credit }))
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault();

    const data = { buyRequestRef: props._id };

    for (const key in credit) {
      credit[key] !== '' ? data[key] = credit[key] : null;
    }

    dispatch(createNewCredit({ credit: data }));
  }

  return (
    <Dialog fullWidth open={props.open} onClose={handleClose} >
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Calcular crédito</Typography>
          <IconButton onClick={handleClose}><Close /></IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.container}>
          <Grid container>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>

              <TextField select label="¿Tiene trabajo?"  name='worked' value={credit.worked} onChange={handleChange} color='secondary'>
                <MenuItem value={true} > Sí </MenuItem>
                <MenuItem value={false} > No </MenuItem>
              </TextField>

            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="¿Cuánto tiempo tiene trabajando en ese lugar?" name='timeWorking' value={credit.timeWorking} onChange={handleChange} color='secondary' />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField type='number' label="¿Cuánto gana al mes?" name='monthlyMoney' value={credit.monthlyMoney} onChange={handleChange} color='secondary'  />
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField select label="¿Tiene otros créditos?" name='otherCredits' value={credit.otherCredits} onChange={handleChange} color='secondary' >
                <MenuItem value={true} >Sí</MenuItem>
                <MenuItem value={false} >No</MenuItem>
              </TextField>
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField select label="¿Tiene dependientes económicos?" name='economicDependents' value={credit.economicDependents} onChange={handleChange} color='secondary' >
                <MenuItem value={true} >Sí</MenuItem>
                <MenuItem value={false} >No</MenuItem>
              </TextField>
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField type='number' label="¿Cuántos dependientes económicos tiene?" name='numEconomicDependents' value={credit.numEconomicDependents} onChange={handleChange} color='secondary'/>
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField select label="Aprobado /No aprobado" name='aprobado' value={credit.aprobado} onChange={handleChange} color='secondary' >
                <MenuItem value={'aprobado'} >Aprobado</MenuItem>
                <MenuItem value={'no aprobado'} >No aprobado</MenuItem>
              </TextField>
            </Grid>
            <Grid item sx={styles.fieldWrapper} xs={12} md={4}>
              <TextField label="Monto" name='amount' type='number' value={credit.amount} onChange={handleChange} color='secondary' />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProfilingCalculateCredit;
