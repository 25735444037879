import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: {},
  list: [],
  metrics: {},
}

const reconversionsSlice = createSlice({
  name: 'reconversions',
  initialState,
  reducers: {
    setReconversionsList: (state, { payload }) => ({ ...state, list: [...payload.reconversions] }),
    setCurrentReconversion: (state, { payload }) => ({ ...state, current: { ...payload.reconversion } }),
    setReconversionsMetrics: (state, { payload }) => ({ ...state, metrics: { ...payload.metrics } }),
    resetReconversionsState: () => ({ list: [], current: {}, metrics: {} })
  }
});

export const { setReconversionsList, setCurrentReconversion, resetReconversionsState, setReconversionsMetrics } = reconversionsSlice.actions;
export default reconversionsSlice.reducer;
