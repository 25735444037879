import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllAdvisers } from "../api/adviserApi";
import { setAdviserList } from "../slices/advisersSlice";
import { handleError } from "../slices/errorsSlice";
import { endLoading, startLoading } from "../slices/loadingSlice";






export const getAdviserList = createAsyncThunk('adviserd/getAdviserList', ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;
  
    dispatch(startLoading());
  
    getAllAdvisers(token, query)
      .then(response => response.status === 200 ? response.json() : Promise.reject(response))
      .then(data =>  dispatch(setAdviserList({ advisers: data.advisers })))
      .catch(err => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  });






