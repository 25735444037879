import { createAsyncThunk } from "@reduxjs/toolkit";
import { createCredit } from "../api/creditApi";
import { showAlert } from "../slices/alertsSlice";
import { handleError } from "../slices/errorsSlice";
import { endLoading, startLoading } from "../slices/loadingSlice";




export const createNewCredit = createAsyncThunk('credit/createNewCredit', ({ credit }, { dispatch, getState }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    createCredit(token, credit)
        .then(response => response.status === 201 ? response.json() : Promise.reject(response))
        .then(() => dispatch(showAlert({ message: 'Datos actualizados', type: 'success' })))
        .catch(err => dispatch(handleError(err)))
        .finally(() => dispatch(endLoading()));
});





