import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: {},
  list: [],
  listForFirm: [],
  history: [],
}

const buyRequestsSlice = createSlice({
  name: 'buyRequests',
  initialState,
  reducers: {
    setBuyRequestsList: (state, { payload }) => ({ ...state, list: [...payload.buyRequests] }),
    setCurrentBuyRequest: (state, { payload }) => ({ ...state, current: { ...payload.buyRequest } }),
    setBuyRequestsListForFirm: (state, { payload }) => ({ ...state, listForFirm: [...payload.buyRequests] }),
    setBuyRequestsListHistory: (state, { payload }) => ({ ...state, history: [...payload.history] }),
    resetBuyRequestsState: () => ({ list: [], current: {}, listForFirm: [], history: [] })
  }
});

export const { setBuyRequestsList, setCurrentBuyRequest, resetBuyRequestsState, setBuyRequestsListForFirm,setBuyRequestsListHistory } = buyRequestsSlice.actions;
export default buyRequestsSlice.reducer;
