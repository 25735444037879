import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createFile,
  deleteFile,
  getFiles,
  getFilesOptions,
  updateFile,
  uploadFile as uploadUrl,
} from "../api/filesApi";
import { setFilesList, setFilesOptions } from "../slices/filesSlice";
import { showAlert } from "../slices/alertsSlice";
import { handleError } from "../slices/errorsSlice";
import { startLoading, endLoading } from "../slices/loadingSlice";
import { getUploadFileUrl, uploadFile } from "../api/utilsApi";
import { toNormalForm } from "../helpers/common";

export const getFilesList = createAsyncThunk(
  "files/getFilesList",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getFiles(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) => dispatch(setFilesList({ files: data.documentations })))
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const createFileRecord = createAsyncThunk(
  "files/createFileRecord",
  ({ file, doc, query, callback }, { getState, dispatch }) => {
    const { token } = getState().auth;
    dispatch(startLoading());

    createFile(token, file)
      .then((response) =>
        response.status === 201 ? response.json() : Promise.reject(response)
      )
      .then((data) => data.document_id)
      .then((document_id) =>
        getUploadFileUrl(
          token,
          `tmp/properties/${query.propertyId}/docs/expediente/comprador`,
          toNormalForm(doc?.name?.trim()?.replace(" ", "-").toLowerCase())
        ).then((response) => {
          console.log(response);
          return {
            url: response.url,
            signedUrl: response.signedUrl,
            document_id,
          };
        })
      )
      .then(({ url, signedUrl, document_id }) =>
        uploadFile(signedUrl, doc).then((response) =>
          response.status === 200
            ? { document_id, url }
            : Promise.reject(response)
        )
      )
      .then(({ document_id, url }) => uploadUrl(token, document_id, url))
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then(() => dispatch(getFilesList({ query })))
      .then(() =>
        dispatch(showAlert({ message: "Documento agregado", type: "success" }))
      )
      .then(() => callback())
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const deleteCurrentFile = createAsyncThunk(
  "files/deleteCurrentFile",
  ({ id }, { getState, dispatch }) => {
    const { token } = getState().auth;
    const { list } = getState().files;

    dispatch(startLoading());

    deleteFile(token, id)
      .then((response) => (response.status === 200 ? true : Promise.reject()))
      .then(() =>
        dispatch(
          setFilesList({ files: list.filter((item) => item._id !== id) })
        )
      )
      .then(() =>
        dispatch(showAlert({ message: "Documento eliminado", type: "success" }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);

export const fetchFilesOptions = createAsyncThunk(
  "files/fetchFilesOptions",
  ({ query }, { getState, dispatch }) => {
    const { token } = getState().auth;

    dispatch(startLoading());

    getFilesOptions(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then((data) =>
        dispatch(setFilesOptions({ options: data.documentations }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);
export const updateCurrentDoc = createAsyncThunk(
  "documentations",
  ({ id, fileData }, { getState, dispatch }) => {
    const { token } = getState().auth;
    dispatch(startLoading());

    updateFile(token, id, fileData)
      .then((response) =>
        response.status === 200 ? response.json() : Promise.reject(response)
      )
      .then(() =>
        dispatch(showAlert({ message: "Actualizado", type: "success" }))
      )
      .catch((err) => dispatch(handleError(err)))
      .finally(() => dispatch(endLoading()));
  }
);
