import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Close, AttachFile } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  DialogActions,
  Grid,
  Divider,
  Typography,
  IconButton,
  TextField,
  MenuItem,
} from "@mui/material";
import {
  createFileRecord,
  fetchFilesOptions,
  getFilesList,
} from "../../../actions/filesActions";
import { FileIntegrationItem } from "./FileIntegrationItem";
import { updateCurrentBuyRequest } from "../../../actions/buyRequestsActions";
import { BUY_REQUESTS_STATUS } from "../../../constants/buyRequestsConstants";
import { isValidListDocs } from "../../../utils/fileUtils";
import { useRef } from "react";

const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingTop: 0,
    paddingRight: "1rem",
  },
  title: {
    my: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrapper: {
    px: "1rem",
  },
  iconButton: {
    p: "2.5rem",
    borderStyle: "dashed",
  },
  newFile: {
    textAlign: "center",
  },
  column: {
    px: "1rem",
  },
};

const FileIntegration = (props) => {
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [doc, setDoc] = useState(null);
  const files = useSelector((state) => state.files.list);
  const options = useSelector((state) => state.files.options);
  const isDisabled = useMemo(
    () => isValidListDocs(files, props.buyerRef?.married),
    [files]
  );
  const handleClose = () => props.onClose();
  const handleTypeChange = (e) => setType(e.target.value);
  const handleFileChange = (e) => setDoc(e.target.files[0]);
  const handleOpenFile = () => inputFile.current.click();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const file = {
      type,
      buyerRef: props.buyerRef?._id,
      reUploaded: files.some((file) => file.type === type),
    };
    dispatch(
      createFileRecord({
        file,
        doc,
        query: {
          buyerRef: props.buyerRef?._id,
          propertyId: props?.propertyRef?._id,
        },
        callback: () => {
          inputFile.current.value = null;
          setDoc(null);
          setType("");
        },
      })
    );
  };

  const handleToSigning = () => {
    dispatch(
      updateCurrentBuyRequest({
        id: props._id,
        buyRequest: { status: BUY_REQUESTS_STATUS.DEED },
        query: { status: BUY_REQUESTS_STATUS.FILE },
      })
    );
  };

  useEffect(() => {
    if (props.open) {
      let query = "";

      props.buyerRef?.married === true ? (query += "type=married&") : null;
      props.buyerRef?.communityProperty === true
        ? (query += "type=communityProperty")
        : null;

      dispatch(fetchFilesOptions({ query }));
      dispatch(getFilesList({ query: { buyerRef: props.buyerRef?._id } }));
    }
  }, [props.open]);

  const getListDocs = (listDocs = [], userDocs = []) => {
    if (userDocs.length === 0) return listDocs;

    const invalidDocs = userDocs
      ?.filter((doc) => doc?.valid === false)
      ?.map((doc) => doc.type);

    const docsUploaded = userDocs
      .map((doc) => doc.type)
      ?.filter((doc) => !invalidDocs.includes(doc));

    const newListDocs = listDocs.filter((doc) => !docsUploaded.includes(doc));

    return newListDocs;
  };

  return (
    <Dialog fullWidth open={props.open} onClose={handleClose}>
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Integrar expediente</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.container}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ ...styles.newFile, ...styles.column }}
            >
              <TextField
                select
                label="Tipo"
                name="type"
                value={type}
                onChange={handleTypeChange}
                color="secondary"
              >
                {getListDocs(options, files).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                variant="outlined"
                color="primary"
                component="label"
                size="large"
                sx={styles.iconButton}
                onClick={handleOpenFile}
                disabled={!type}
              >
                <AttachFile />
              </Button>
              <input
                ref={inputFile}
                hidden
                accept="image/*"
                type="file"
                onChange={handleFileChange}
                id="inputFile"
              />
              <Typography>{doc?.name ?? "Seleccionar archivo"}</Typography>
              <Button onClick={handleSubmit} disabled={!type || !doc}>
                Subir
              </Button>
            </Grid>
            <Grid item xs={12} md={6} sx={styles.column}>
              <Typography variant="h6">
                Documentos (<b>Casado:</b>{" "}
                {props.buyerRef?.married ? "Si" : "No"},{" "}
                <b>Bienes Mancomunados:</b>{" "}
                {props.buyerRef?.communityProperty ? "Si" : "No"})
              </Typography>
              {options.map((option) => (
                <FileIntegrationItem key={option} type={option} files={files} />
              ))}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button onClick={handleToSigning} disabled={!isDisabled}>
          MANDAR A ESCRITURACION
        </Button>
        <Button onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileIntegration;
