import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getBuyRequestsList } from '../../../actions/buyRequestsActions';
import { BUY_REQUESTS_STATUS } from '../../../constants/buyRequestsConstants';
import ProfilingListTable from './ProfilingListTable';

const Profiling = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBuyRequestsList({ query: { status: BUY_REQUESTS_STATUS.PROFILING } }));
  }, []);

  return (
    <>
      <ProfilingListTable />
    </>
  );
}

export default Profiling;
