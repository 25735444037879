import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    newNotification: 0,
    currentNotification: {},
    notifications: [],
}

const notificationsSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNewNotifications: (state, { payload }) => ({ ...state, newNotification: payload.newNotification }),
        setNotifications: (state, { payload }) => ({ ...state, notifications: payload.notifications }),
        clearNotification: () => ({ newNotification: 0, notifications: [] }),
        setCurrentNotification: (state, { payload }) => ({ ...state, currentNotification: payload.currentNotification, }),
    }
});

export const { setNewNotifications, setNotifications, clearNotification, setCurrentNotification } = notificationsSlice.actions;
export default notificationsSlice.reducer;
