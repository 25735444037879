import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { BUY_REQUESTS_STATUS } from '../../../constants/buyRequestsConstants';
const { PROFILING, PROSPECTS, FILE, DEED, SIGNING } = BUY_REQUESTS_STATUS
const styles = {
    buttons: {
        padding: '1rem',
        background: '#e6e6e6',
        mb: '1rem',
        ml: '1rem',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        cursor: 'pointer'
    },
    link: {
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#718195'
    }
}


export const ReconversionMenu = ({ setStatus, status }) => {

    const { totalLeads = 0, data = [] } = useSelector(state => state.reconversions.metrics);

    const getTotal = (status) => data.find(elem => elem.priority === status)?.total ?? 0;

    return (
        <Grid container spacing={2} justifyContent='center' alignItems='center' width='95%' >
            <Grid item sx={styles.buttons} onClick={() => setStatus('')} >
                <Typography variant='body2' sx={{ ...styles.link, color: status === '' ? 'black' : '#718195' }} >General</Typography>
                <Typography variant='body2' sx={styles.link} >{totalLeads} leads</Typography>
            </Grid>
            <Grid item sx={styles.buttons} onClick={() => setStatus(PROFILING)} >
                <Typography variant='body2' sx={{ ...styles.link, color: status === PROFILING ? 'black' : '#718195' }} >Perfilado</Typography>
                <Typography variant='body2' sx={styles.link} >{getTotal(PROFILING)} leads</Typography>
            </Grid>
            <Grid item sx={styles.buttons} onClick={() => setStatus(PROSPECTS)} >
                <Typography variant='body2' sx={{ ...styles.link, color: status === PROSPECTS ? 'black' : '#718195' }} >Prospectos</Typography>
                <Typography variant='body2' sx={styles.link} >{getTotal(PROSPECTS)} leads</Typography>

            </Grid>
            <Grid item sx={styles.buttons} onClick={() => setStatus(FILE)} >
                <Typography variant='body2' sx={{ ...styles.link, color: status === FILE ? 'black' : '#718195' }} >Expediente</Typography>
                <Typography variant='body2' sx={styles.link} >{getTotal(FILE)} leads</Typography>

            </Grid>
            <Grid item sx={styles.buttons} onClick={() => setStatus(DEED)} >
                <Typography variant='body2' sx={{ ...styles.link, color: status === DEED ? 'black' : '#718195' }} >Escrituracion</Typography>
                <Typography variant='body2' sx={styles.link} >{getTotal(DEED)} leads</Typography>
            </Grid>
            <Grid item sx={styles.buttons} onClick={() => setStatus(SIGNING)} >
                <Typography variant='body2' sx={{ ...styles.link, color: status === SIGNING ? 'black' : '#718195' }} >Firma</Typography>
                <Typography variant='body2' sx={styles.link} >{getTotal(SIGNING)} leads</Typography>
            </Grid>
        </Grid>
    )
}
