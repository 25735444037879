import { Typography, Box, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { USER_TYPES } from '../../../constants/usersConstants';
import { formateDate } from '../../../utils/dateUtils';



export const ListHistory = () => {


    const histories = useSelector(state => state.buyRequests.history);

    return (
        <>
            {
                histories.map(history => (
                    <Box padding='1rem' key={history?._id}>
                        <Typography color="primary" variant='h6' >
                            {formateDate(history.date) + '. '}
                            <span style={{ color: '#718195' }} >
                                Usuario: {USER_TYPES[history?.userType]} {` (${history.user?.name}) `}
                            </span>
                        </Typography>
                        <Typography sx={{ color: '#718195' }} variant='subtitle1' marginLeft='1rem' >
                            {history?.description}
                        </Typography>
                        <Divider sx={{ mb: '.5rem' }} />
                    </Box>

                ))
            }
        </>

    )
}

