import { useState, useEffect, useMemo } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { getFilesList } from "../../../actions/filesActions";
import { updateCurrentBuyRequest } from "../../../actions/buyRequestsActions";
import { useDispatch, useSelector } from "react-redux";
import { DeedFileLink } from "./DeedFileLink";
import { BUY_REQUESTS_STATUS } from "../../../constants/buyRequestsConstants";
const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
  },
  actions: {
    paddingTop: 0,
    paddingRight: "1rem",
  },
  title: {
    my: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrapper: {
    px: "1rem",
  },
};
export const DeedShowFile = (props) => {
  const dispatch = useDispatch();

  const files = useSelector((state) => state.files.list);
  const isValidListDoc = useMemo(
    () => files.length === files.filter((doc) => doc?.valid === true).length,
    [files]
  );
  const existDocInvalid = useMemo(
    () => files.filter((doc) => doc?.valid === false).length >= 1,
    [files]
  );

  useEffect(() => {
    if (props.open) {
      dispatch(getFilesList({ query: { buyerRef: props.buyerRef._id } }));
    }
  }, [props.open]);

  const updateBuyRequest = async (status) => {
    dispatch(
      updateCurrentBuyRequest({
        id: props._id,
        buyRequest: { status: status },
        query: { status: BUY_REQUESTS_STATUS.DEED },
      })
    );
  };

  const handleClose = () => props.onClose();

  const setValidDoc = (doc) => {
    files.map((file) => (file._id === doc._id ? doc : file));
  };

  return (
    <Dialog fullWidth open={props.open} onClose={handleClose}>
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Validar documentación</Typography>

          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.container}>
          {files.map((file) => (
            <DeedFileLink key={file._id} setValidDoc={setValidDoc} {...file} />
          ))}
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button
          onClick={() => updateBuyRequest(BUY_REQUESTS_STATUS.SIGNING)}
          disabled={!isValidListDoc}
        >
          ENVIAR A FIRMA
        </Button>
        <Button
          disabled={!existDocInvalid}
          onClick={() => updateBuyRequest(BUY_REQUESTS_STATUS.FILE)}
        >
          DEVOLVER A EXPEDIENTE
        </Button>
        <Button onClick={handleClose}>CERRAR</Button>
      </DialogActions>
    </Dialog>
    // props.open && (
    //   <td
    //     className={`custom_modal ${props.open && "isOpen"}`}
    //     onClick={handleClose}
    //   >
    //     <div
    //       className="custom_modal__content w-75"
    //       onClick={(e) => e.stopPropagation()}
    //     >
    //       <div className="custom_modal__header">
    //         <h2 className="mt-3">Documentacion</h2>
    //         <div className="col-3 mt-3">
    //           <button
    //             className="btn-close custom_modal_close"
    //             onClick={handleClose}
    //           ></button>
    //         </div>
    //       </div>

    //       <div className="my-5 px-2">
    //         {loading ? (
    //           <Loading />
    //         ) : (
    //
    //           ))
    //         )}
    //       </div>

    //       <div className="custom_modal__footer">
    //         {error !== "" && <div className="deed-error">{error}</div>}
    //
    //       </div>
    //     </div>
    //   </td>
    // )
  );
};
