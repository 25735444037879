import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { firebaseApp } from './firebaseService';

const KEY = 'BE_MpCukvtsXx4H4I3nCgaiuBPyrHpXI-B8R_guh5LMuyLEsym0wDkcDbqtpfITV_Mq_3ieEAuH9mttlukmBD74';

const messaging = isSupported() ? getMessaging(firebaseApp) : null;

const generateTokenMessaging = () => new Promise((resolve, reject) =>
    getToken(messaging, { vapidKey: KEY })
        .then(fcmToken => resolve(fcmToken))
        .catch(error => reject(error))
)



export {
    messaging,
    generateTokenMessaging
}





